//import uthmanhifzkhana from "../img/uthmanhifzkhana.jpeg";
//import helpabdullah from "../img/helpabdullah.jpeg";

//serial: 1

const LastTen = [
  {
    _ID: "sdqmel001.1",
    //projectImg: uthmanhifzkhana,
    projectHeading: "হেফজখানার জন্য সৌর বিদ্যুৎ",
    slug: "uthman-hifzkhana",
    projectDetails: (
      <div>
        <h2> হেফজখানার জন্য সৌর বিদ্যুতের ব্যবস্থা</h2>

        <p>
          হযরত উসমান ইবনে আফফান (রঃ) হেফজখানাটি কক্সবাজার জেলার কুতুবদিয়া থানায়
          অবস্থিত। এটি ২ বছর আগে প্রতিষ্ঠিত হয়। এই দুই বছরে বেশ কয়েকজন হিফজ
          সম্পন্ন করে। গ্রামে অবস্থিত হওয়ার কারনে উক্ত হাফেজখানায় বিদ্যুৎের
          সংযোগ নাই। সোলার প্যানেলের মাধ্যমে লাইটের ব্যবস্থা করা গেলেও টাকা
          স্বল্পতার কারনে ছাত্রদের জন্য ফ্যানের ব্যবস্থা করা সম্ভব হয়নি। যার ফলে
          গরমে স্টুডেন্টদের বেশি কষ্ট হয়ে যায়।
        </p>
        <p>
          উক্ত মাদ্রাসায় সোলার প্যানেলের মাধ্যমে ফ্যানের ব্যবস্থা করতে হলে মোট
          দশ হাজার টাকা প্রয়োজন।
        </p>

        <p>যোগাযোগ : 01537384809</p>
        <p>ঠিকানা: নজুবাপের পাড়া, উত্তর ধুরুং, কুতুবদিয়া, কক্সবাজার</p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">bKash: 01537384809 (Personal )</h4>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/saifu.saifu.7902`,
    projectOrgName: "উসমান ইবনে আফফান (রঃ) হেফজখানা",
    projectOrg: {
      _ID: "sdqmel001",
      name: "উসমান ইবনে আফফান (রঃ) হেফজখানা",
    },
    projectCreated: "April 2023",
    projectTag: "Hifz, Solar",
    projectLink: "https://sadaqahmadeeasy.com/lastten/uthman-hifzkhana",
  },

  //  {
  //    _ID: "sdqmel001.2",
  //    projectImg: helpabdullah,
  //    projectHeading: "কিডনির কার্যক্ষমতা কমে নষ্ট হওয়ার পথে",
  //    slug: "help-abdullah",
  //    projectDetails: (
  //      <div>
  //        <h2> কিডনির কার্যক্ষমতা কমে নষ্ট হওয়ার পথে</h2>

  //        <p>
  //          আমার ছেলের উরোলজিক্যাল প্রব্লেমের জন্য কিডনি ডেমেজ হয়ে
  //          গিয়েছে।বাংলাদেশে অনেক বার অপেরেশন করিয়েছি প্লাস এখানে ট্রিটমেন্ট করাতে
  //          করাতে লাখ লাখ টাকা চলে গেসে।দেশের বাইরে নিব অনেক টাকা দরকার।দয়া করে
  //          পোস্ট টা শেয়ার করে জান।
  //        </p>
  //        <p>
  //          আবদুল্লাহ প্রি-ম্যাচিউর হওয়াতে NICU তে ভর্তি ছিল।NICU থেকে জানানো হয
  //          আবদুল্লাহর হার্টে ছিদ্র, হার্নিয়া, বো লেগ এবং ঠিকমত ইউরিন পাস করতে
  //          পারতো ন, যার কারনে ওর কিডনিটা ড্যামেজ হওয়া শুরু করে। Creatinine বেড়ে
  //          যাওয়ায় ডায়ালাইসিস করতে গিয়ে মৃত্যুশয্যায় পৌঁছে যায়। ডক্টররা তখন জানান
  //          হয়তো আর কয়েক ঘন্টাই বাঁচবে।ডায়ালাইসিস চলাকালীন বাচ্চার অন্ডকোষে পানি
  //          জমা শুরু হতো।ডক্টররা Nephrostomy সার্জারি করতে গিয়ে পিঠ কেটে
  //          Nephrostomy না করে সেলাই করে দেন আর জানান ওর কিডনি টা ভালো আছে কিন্তু
  //          কিডনিটার সাইজ অনেক ছোট।তারপর ক্যথেটার সহ ডিসচার্জ দেয়া হয় এবং উন্নত
  //          চিকিৎসার জন্য ইন্ডিয়া নিতে বলেন।দীর্ঘদিন NICU তে থাকায় ইন্ডিয়া নিয়ে
  //          চিকিৎসা চালানোর মত আর্থিক সক্ষমতা ছিলনা।বাসায় আসার পর ক্যাথেটার চেন্জ
  //          করে করে দিন কাটছিল কিন্তু বার বার ক্যথেটার ইনফেকশন নিয়ে দীর্ঘদিন
  //          হাসপাতালে ভর্তি থাকা লাগত।ওর ইউরিন রিফ্লাক্স হত, ক্যাথেটার খুলে দিলেই
  //          ক্রিয়েটিনিন বেড়ে যেত।ডক্টররা জানান ওর ইউরিন ব্লাডার থেকে উল্টো পথে
  //          কিডনি তে ব্যাক করত এবং প্রস্রাবের নালী চিকন। পর পর ৫ বার অপারেশন করা
  //          হয়।পায়খানার রাস্তা কেটে বড় করে দেয়া হয়,হার্নিয়া ফেলে দেয়া হয়, Ureter
  //          পেটের বাহিরে বের করে দেয়া হয় এবং আর একটা অপারেশন করতে গিয়ে দেখেন ওর আর
  //          একটা কিডনি আছে কিন্তু অনেক ছোট তাই কেটে পেলা হয়।অপারেশন করেও
  //          প্রস্রাবের কোন সমাধান হয়নি।পেটের রাস্তা থেকে কোন ইউরিন আসতো না তাই
  //          আবদুল্লাহর শরিরে পানি জমতে শুরু করে, সাথে Creatinine অনেক বেশি বেড়ে
  //          যেতে থাকে।বার বার ক্যাথেটার করেও কাজ না হওয়ায় পেটের ভেতর ক্যাথেটার সহ
  //          ডিসচার্জ দেয়া হয়।
  //        </p>

  //        <p>
  //          এভাবে থাকতে থাকতে Creatinine আরো বেড়ে যায় সাথে বার বার ইনফেকশন হতে
  //          থাকে।কয়েক মাস পর আবার সার্জারি করে কিডনিতে Stent দিয়ে ইউরেটার আবার
  //          পেটের ভেতর ঢুকিয়ে ক্যথেটার দিয়ে বাসায় পাঠানো হয়।৩ মাস পর ওটি তে নিয়ে
  //          Stent রিমুভের পর আবদুল্লাহর অবস্থা আরো খারাপ হতে থাকায় Vesicostomy
  //          করার সিদ্ধান্ত নেন ডক্টররা। Vesicostomy করার পর অবস্থা এতটাই বাজে হয়
  //          যে পেট দিয়ে প্রস্রাব তোহ বের হতোই না বরং সার্জারির জায়গাটা অনেক বাজে
  //          হয়ে যায়।কয়েক মাস ভোগান্তির পর আরেকটি সার্জারি করে ঠিক করা হয় এবং
  //          ক্যাথেটার খুলে দেযা হয়।আরো অপারেশন করতে গিয়ে আরো কিছু নতুন জটিলতার
  //          সৃষ্টি হয়, যার মধ্যে একটি প্রস্তাব এবং পায়খানার রাস্তা মিলে
  //          যাওয়া।প্রস্রাবের সাথে এখন পায়খানা আসে এবং পায়খানার রাস্তা দিয়ে
  //          প্রস্রাব আসে।বর্তমানে কিডনির কার্যক্ষমতা কমে নষ্ট হওয়ার পথে।এখন দেশে
  //          ডায়ালাইসিস করানো ছাড়া আর কোন চিকিৎসা নেই যা আবদুল্লাহর জন্য রিস্কি।
  //        </p>
  //        <p>
  //          এই ৪ বছরে চিকিৎসায় লাখ লাখ টাকা চলে গেছে। নেফ্রোলোজি ডিপার্টমেন্ট থেকে
  //          সার্জারি, সার্জারি থেকে নেফ্রোলোজি তে ভর্তি থাকতে হয়... এমনকি সম্পুর্ন
  //          রোজার মাস,ইদ কাটে হসপিটাল বেডে।হসপিটালে ভর্তির কারনে আবদুল্লাহর বাবা
  //          ঠিকমত কাজে যেতে পারতেননা।এখনো কোন সমাধান হয়নি রিপোর্ট সব খারাপ আসায় ডঃ
  //          ডায়ালাইসিসের জন্য হসপিটালে ভর্তি হতে বলছেন।ইন্ডিয়ায় যোগাযোগ করা হলে
  //          উনারা বলছেন সার্জিক্যাল প্রবলেম গুলো দ্রুত সলভ করে ট্রান্সপ্লান্টের
  //          দিকে আগাতে।
  //        </p>
  //        <p>
  //          ইন্ডিয়ায় নেবার কথা আরো আগেই বলা হয়েছিল কিন্তু টাকার অভাবে বেশি দূর
  //          আগানো সম্ভব হয়নি।ব্যায় বহুল এই চিকিৎসা চালাতে সকলের একটু সহযোগিতা
  //          প্রয়োজন।আবদুল্লাহর শরিরে পানি জমে গেছে,হাই ব্লাড প্রেসার, রাতে ঘুমাতে
  //          পারেনা।ছটফট করতে থাকে আর গোঙায়...
  //        </p>
  //        <p>
  //          <b>আবদুল্লাহর আম্মুর সাথে যোগাযোগ নাম্বার 01791833009</b>
  //        </p>

  //        <h3>
  //          রেফারারের মন্তব্যঃ দেশে লাখ লাখ টাকা খরচ করে পরিবার প্রায় নিঃস্ব।
  //          আব্দুল্লাহর বাবার নিউমার্কেটে দোকান ছিল। আগুনে তাদের বাকিসব ব্যাবসাও
  //          শেষ হয়ে যায়।
  //          <br />
  //          <br />
  //          <a
  //            href="https://fb.watch/k0F3ikX-5q/?mibextid=ykz3hl"
  //            target="_blank"
  //            rel="noreferrer"
  //            className="text-primary"
  //          >
  //            নিউমার্কেট এ আগুনে আব্দুল্লাহর বাবার সব হারানোর পর সাংবাদিকের
  //            সাক্ষাৎকার এর ভিডিও
  //          </a>
  //        </h3>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">ডোনেশান পাঠানোর নাম্বার:</h4>
  //        <p> bKash: 01791833009 (Personal)</p>
  //        <p> Nagad: 01791833009 (Personal)</p>

  //        <hr />
  //        <h4 className="mb-4">একাউন্ট নাম্বার:</h4>
  //        <p>A/C Name: Sadia Akter Sabrina</p>
  //        <p>Bank A/C Number: 20501290203999213 </p>
  //        <p className="mb-4"> Bank Name: Islami Bank Bangladesh Limited</p>
  //        <p>Branch: New market, Dhaka</p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/jawad.wretched`,
  //    projectOrgName: "Mahi Al Jawad",
  //    projectOrg: {
  //      _ID: "sdqmel002",
  //      name: "Mahi Al Jawad",
  //    },
  //    projectCreated: "April 2023",
  //    projectTag: "Child, Treatment",
  //    projectLink:
  //      "https://www.facebook.com/jawad.wretched/posts/pfbid02L3FU3HPwREcQsXSF9wijctDNbqAhWHZ61awzvFrCxJREwWXKfYFvuotPxR4mwv1Jl",
  //  },
];

export function getLastTen() {
  return LastTen;
}
