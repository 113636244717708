import JamiyaHafizulUlum from "../img/JamiyaHafizulUlum.jpeg";
import mahadulIman from "../img/mahadulIman.jpeg";
import markajLugatularabia from "../img/markajLugatularabia.jpeg";
import bahrululum from "../img/bahrululum.jpeg";
import ummulmumenincover from "../img/ummulmumenincover.jpeg";
import ummulmumeninliflet from "../img/ummulmumeninliflet.jpeg";
//import mosquekutubdia from "../img/mosquekutubdia.jpeg";
//import mosquekutubdianame from "../img/mosquekutubdianame.jpeg";
import jamiatululumilislamia from "../img/jamiatululumilislamia.jpeg";

import lutiadarulquran from "../img/lutiadarulquran.jpeg";

//last serial:15

const MadrasaList = [
  {
    _ID: "sdqme023.1",
    projectImg: markajLugatularabia,
    slug: "markajul-lugatil-arabiyyah",
    projectHeading: "মারকাজুল লুগাতিল আরাবিয়া",
    projectDetails: (
      <div>
        <p>লিখেছেন এস এম নাহিদ হাসানঃ </p>
        <p>
          আজকে এক বরকতময় কাজে অংশ নিতে গিয়েছিলাম প্রিয় মারকাজুল লুগাতিল আরাবিয়া
          বাংলাদেশের নিজস্ব ভবন নির্মাণের স্থানে। এখানেই হবে স্বপ্নের আরবী ভাষার
          ঠিকানা। শাইখ Mohiuddin Faroqi হাফিজাহুল্লার তত্ত্বাবধানে পরিচালিত এই
          প্রতিষ্ঠান বাংলাদেশে আরবি ভাষা নিয়ে উচ্চতর গবেষণার একটি অন্যতম
          তীর্থস্থান বলা যায়। আমি নিজেকে এই প্রতিষ্ঠানের একজন খাদেম বলে মনে করি
          আলহামদুলিল্লাহ। এর নির্মিতব্য ভবনের ডিজাইনও আমিই করবো ইনশাআল্লাহ।
          ভাইয়েরা এর নির্মাণ কাজে আমরা শরীক হব ইনশাআল্লাহ। আপনার সাদাকাহ জারিয়া
          পাঠানোর একাউন্ট নিচে তুলে দিলাম। প্লিজ এগিয়ে আসুন।
        </p>
        <p>মাদরাসায় দান করতে চাইলে এই মাদরাসায় করতে পারবেন ইনশাআল্লাহ।</p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <p>Contact: 01736-211755 </p>
        <p>
          Account name: Md. Mohiuddin || Account no: 20502050204501308 || Islami
          Bank, Dhanmondhi Branch
        </p>
      </div>
    ),
    projectFbProfile: `https://fb.com/profile.php?id=100005435299487`,
    projectOrgName: "মারকাজুল লুগাতিল আরাবিয়া",
    projectOrg: { _ID: "sdqme023", name: "মারকাজুল লুগাতিল আরাবিয়া" },
    projectCreated: "February 2022",
    projectTag: "আরবি, Madrasa",
    projectLink:
      "https://fb.com/permalink.php?story_fbid=1812869622237530&id=100005435299487",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  {
    _ID: "sdqme0015.1",
    projectImg: jamiatululumilislamia,
    slug: "jamiatul-ulumil-islamia",
    projectHeading: "জামিয়াতুল উলুমিল ইসলামিয়া",
    projectDetails: (
      <div>
        <h4 className="mb-2">
          আমরা গত তিন বছর ধরে সেখানে প্রতি শনিবার যাই মাগরিব থেকে এশা পর্যন
          দ্বীনিয়াত ক্লাসে। এখানকার উস্তাদগণ সবাই অনেক আন্তরিক, আমাদেরকে বেশ
          সমীহ করেন। আমরা উনাদের আন্তরিকতায় মুগ্ধ এবং কৃতজ্ঞ
        </h4>

        <p>
          রিসালাতুল বিডি মোহাম্মদপুরে জামিয়াতুল উলুমিল ইসলামিয়া মাদ্রাসার অধীনে
          একটা কার্যক্রম। আমরা গত তিন বছর ধরে সেখানে প্রতি শনিবার যাই মাগরিব
          থেকে এশা পর্যন দ্বীনিয়াত ক্লাসে। এখানকার উস্তাদগণ সবাই অনেক আন্তরিক,
          আমাদেরকে বেশ সমীহ করেন। আমরা উনাদের আন্তরিকতায় মুগ্ধ এবং কৃতজ্ঞ।
          আল্লাহ উনাদের ইলমে অনেক অনেক বারাকাহ দান করুন এবং হায়াত দারাজ করুন।
          এখানকার মুহতামিম সাহেব মুফতি মাহমুদুল হাসান হাফিযাহুল্লাহ থেকে শুরু
          করে, মাওলানা আব্দুল গাফফার, মাওলানা তাহমিদুল মওলা, মাওলানা ইমরান
          হাসান, মাওলানা আবুল বাশার, মাওলানা শরীফ মুহাম্মাদ, মাওলানা শিব্বীর
          হোসাইন, মাওলানা মোসাদ্দিক হোসাইন, মারুফ হোসাইন, মাওলানা ইহাহিয়া,
          মাওলানা আতিক আনওয়ার - সবাই খুবই আন্তরিকভাবে আমাদের মত জেনারেল শিক্ষিত
          লোকদের ইলম শিক্ষা দিয়ে যাচ্ছেন। আমি বলবো, এটা কোন সাধারণ মাদ্রাসা নয়,
          উনারা আমাদের কথা শোনেন, বোঝেন, গুরুত্ব দেন, একসাথে কাজ করতে চান,
          আলহামদুলিল্লাহ।
        </p>

        <p>
          এই মাদ্রাসার ভবনটা স্টিল স্ট্রাকচার বিল্ডিং। তিনতলা পর্যন্ত হয়েছে,
          বেইজমেন্ট আছে - যেখানে রিসালাতুল বিডির ক্লাসগুলো হয়। শুরু থেকে এ
          পর্যন্ত অনেকেই অর্থনৈতিকভাবে শরীক হয়েছেন, কিন্তু কয়েক বছর ধরে উনাদের
          এই নির্মাণকাজের খরচের জন্য প্রায় ৩৫-৪০ লক্ষ টাকা ঋণ ঝুলছে। আমরা চাইলে
          এই ঋণের পরিমাণ কিছুটা কমিয়ে আনতে পারি, আনা উচিত। আর এটা সাদাক্বায়ে
          জারিয়ার খুবই উত্তম একটা সোর্স হবে ইনশা আল্লাহ, আর রমাদানে সাদাক্বা
          করলে এর প্রতিদান তো অনেক অনেকগুন হবে তা বলার অপেক্ষা রাখে না। কমেন্টে
          (রেফারারের পোস্টের কমেন্ট চেক করুন) মাওলানা আতিক আনওয়ার এর প্রোফাইল
          লিংক দিচ্ছি, কোন প্রশ্ন থাকলে উনাকে করতে পারেন ইনশা আল্লাহ।
        </p>
        <p>চলুন আমাদের হাত সাধ্যমত সর্বোচ্চ প্রসারিত করি।</p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">সাদাকাহ পাঠানোর নাম্বারঃ</h4>
        <p>নগদ : 01759 958939 (Personal)</p>
        <p>নগদ : 01841 624282 (Personal)</p>
        <p>রকেট : 01922 1060756 </p>
        <hr />
        <h4 className="mb-4">ব্যাংক একাউন্টঃ</h4>
        <p>
          Bank: Islami Bank Bangladesh
          <br />
          Account Name: SUPREME FOOD AND BEVERAGE
          <br />
          Account No.: 20502230100253913
          <br />
          Branch: VIP ROAD BRANCH, DHAKA
        </p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/mahbub687/`,
    projectOrgName: "জামিয়াতুল উলুমিল ইসলামিয়া",
    projectOrg: {
      _ID: "sdqme0015",
      name: "জামিয়াতুল উলুমিল ইসলামিয়া",
    },
    projectCreated: "March, 2024",
    projectTag: "ঋণ, Building",
    projectLink:
      "https://www.facebook.com/mahbub687/posts/pfbid02pox5QgBrVpJQnRtZaZhz293GFk3N5hqHfTDETtWskBxF5p3oJJLcThEDnCzQjAHSl",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  {
    _ID: "sdqme007",
    projectImg: JamiyaHafizulUlum,
    slug: "jamia-hafezul-ulum",
    projectHeading: "জামিয়া হাফেজুল উলুম আল-ইসলামিয়া",
    projectOrgName: "জামিয়া হাফেজুল উলুম আল-ইসলামিয়া",
    projectDetails: `আলহামদুলিল্লাহ আমাদের জামিয়া হাফেজুল উলুম আল-ইসলামিয়ার
  অযু ও বাথরুমের কাজ শেষ করে , পুনরায় ৯জন শিক্ষক সহ - হিফজ ও নাজেরা বিভাগের আবাসিক ঘর এবং পাশাপাশি ২০০/২৫০ জন ছাত্র শিক্ষক নামাজ আদায় করতে পারে মতো মসজিদ নির্মাণ কাজ শুরু করতে যাচ্ছি
  মাটি লেভেলিং কাজ চলছে । এতে সহযোগিতা করে যাচ্ছেন এক্সপেক্টা অব বাংলাদেশ লিঃ,মিঃ  এর উর্ধ্বতন কর্মকর্তা ইন্জিনিয়ার জনাব মোঃ নাসির উদ্দীন দিদার ও
  রাজিব কুমার দাস
  এজন্য আমরা সবাই কৃতজ্ঞ । দেশের ও জনগণের উন্নয়ন কামনা করছি
  তাই দেশে বিদেশের দ্বীন প্রিয় সকল ভাইদের নিকট বিশেষ করে  দোয়ার আবেদন করছি
  আল্লাহ তায়ালা যেন অত্র প্রতিষ্ঠানের যাবতীয় প্রয়োজন গায়েবী খাজানা থেকে পুরণ করে দেন ।
  আমীন ইয়া রাব্বুল আলামীন
  `,
    projectFbProfile1: `https://fb.com/profile.php?id=100077657504454`,
    projectFbProfile2: `https://fb.com/ahmedsagor.ctg`,
    projectContact: `বিকাশ (পার্সোনাল): Removed ||
  যোগাযোগঃ 01739-871496 (মুফতি কামরুল ইসলাম - শিক্ষক অত্র প্রতিষ্ঠান)
  `,
    projectOrg: {
      _ID: "sdqme007",
      name: "জামিয়া হাফেজুল উলুম আল-ইসলামিয়া",
    },
    projectCreated: "Januray 2021",
    projectTag: "মাদ্রাসা, Education",
    projectLink:
      "https://www.facebook.com/permalink.php?story_fbid=3025836704336789&id=100007314665018",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  //  {
  //    _ID: "sdqmet0012.1",
  //    projectImg: mosquekutubdia,
  //    slug: "mosque-kutubdia",
  //    projectHeading: "রুজ্জার পাড়া জামে মসজিদ নির্মাণ",
  //    projectDetails: (
  //      <div>
  //        <h4 className="mb-2">
  //          রুজ্জার পাড়া জামে মসজিদ নির্মার্ণে সাহায্য করে সাদাকায়ে জারিয়ার কাজে
  //          অংশ নিন
  //        </h4>
  //        <h5 className="mb-4">
  //          Participate in Sadaqah-a-jaria work by helping to build the mosque
  //        </h5>
  //        <p>
  //          রুজ্জার পাড়া জামে মসজিদ টি ২০১৪ সালের দিকে স্থাপিত হয়। এটি একেবারে
  //          দূর্গম এলাকায় অবস্থিত। যখন নির্মান হয় তখন কোনরকম একটা ঘরে মুসল্লিরা
  //          নামাজ আদায় করতে পারতো। পরবর্তী তে ঝুকিপূর্ণ হওয়ার কারনে ২০২২ সালের
  //          শুরুর দিকে ভেঙে ফেলা হয়। বছরের শুরুর দিকে কাজ শুরু হলেও অর্থের কারনে
  //          মাঝপথে কাজ আটকা পড়ে। এলাকায় তেমন ধনসম্পদ ওয়ালা ব্যক্তি নেই। সবার
  //          সহযোগীতার মাধ্যমে মসজিদ চলতেছে। এটি আপাতত একতলা নির্মান করা হবে।
  //        </p>
  //        <img
  //          src={mosquekutubdianame}
  //          alt="mosquekutubdianame"
  //          className="w-25 mt-3 mb-3"
  //        />

  //        <p>মসজিদের ঠিকানাঃ রুজ্জার পাড়া, উত্তর ধুরুং, কুতুবদিয়া, কক্সবাজার।</p>
  //        <p>সার্বিক যোগাযোগঃ 01815695995, নামঃ সাইফুর রহমান</p>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <p>বিকাশ: Removed</p>
  //        <p>Contact: 01537384809</p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/saifu.saifu.7902`,
  //    projectOrgName: "রুজ্জার পাড়া জামে মসজিদ",
  //    projectOrg: {
  //      _ID: "sdqmet0012",
  //      name: "রুজ্জার পাড়া জামে মসজিদ",
  //    },
  //    projectCreated: "March 2023",
  //    projectTag: "মসজিদ, Mosque",
  //    projectLink: "https://sadaqahmadeeasy.com/madrasa/mosque-kutubdia",
  //    get getURL() {
  //      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
  //    },
  //  },

  {
    _ID: "sdqmet0014.1",
    projectImg: lutiadarulquran,
    slug: "madrasa-lutiadarulquran",
    projectHeading: "লুটিয়া দারুল কোরআন মাদ্রাসা",
    projectDetails: (
      <div>
        <h4 className="mb-4">
          আপনিও এক বা একাধিক জায়নামাজের সমপরিমাণ মূল্য পরিশোধ করতে পারেন।
        </h4>
        <p>
          দ্বীন দরদী ইসলান প্রিয় ভাই ও বোনেরা, আস্সালামু আলাইকুম ওরহমাতুল্লাহ,
          লুটিয়া দারুল কোরআন হাফিজিয়া মাদ্রাসা ও এতিম খানার ছাএদের থাকার জায়গার
          সল্পতা রয়েছে যার কারনে মাদ্রাসার কমিটি এবং গ্রাম বাসির আলোচনা সাপেক্ষে
          ১২ শতক জায়গা ক্রয়ের সিদ্ধান্ত গ্রহন করা হয়েছে যার মূল্য ৭ লক্ষ টাকা,
          ছদকায়ে জারিয়ায় আপনিও মাদ্রাসার জমি দাতা হয়ে আজিবন সদস্য হতে পারেন, এক
          জায়নামাজ সমপরিমাণ /১০ স্কয়ার ফিট জায়গার মূল্য ১৩০০শত টাকা, আপনিও এক বা
          একাধিক জায়নামাজের সমপরিমাণ মূল্য পরিশোধ করতে পারেন।
        </p>
        <a
          href="https://www.facebook.com/ikayesdesginer/videos/276480074729211"
          className="text-primary"
          target="_blank"
          rel="noreferrer"
        >
          মাদ্রাসা এবং জায়গার ভিডিও
        </a>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <p>নগদ: 01770337947</p>
        <hr />
        <p>
          ডাচ্ বাংলা ব্যাংক
          <br />
          Branch: দিঘলিয়া
          <br />
          Ac no: 7017327471803
        </p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/ikayesdesginer`,
    projectOrgName: "লুটিয়া দারুল কোরআন হাফিজিয়া মাদ্রাসা ও এতিম খানা",
    projectOrg: {
      _ID: "sdqmet0014",
      name: "লুটিয়া দারুল কোরআন হাফিজিয়া মাদ্রাসা ও এতিম খানা",
    },
    projectCreated: "May 2023",
    projectTag: "জায়গা, Sadaqah",
    projectLink:
      "https://www.facebook.com/asad.jamil.58118/posts/pfbid0K6p8f2pVZwt2YoF51pZXkkAKfzsuS9RNhJqd5BTXSofUJ8wmKy3ZeY3pUb7rXKHZl",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  {
    _ID: "sdqmet008.1",
    projectImg: bahrululum,
    projectHeading: "বাহরুল উলুম ইসলামিয়া মাদরাসা ও এতিম খানা",
    slug: "bahrul-ulum",
    projectDetails: (
      <div>
        আসসালামু আলাইকুম ওয়া রহমাতুল্লাহি ওয়া বারকাতুহু
        <br />
        <br />
        <p>
          কুরবানীর ঈদের পরপর লক্ষীপুর গিয়েছিলাম সফরে, সাথে ছিল জুলফিকার এবং
          শান্তু ভাই। কাওসার ভাই একটা মাদ্রাসা ভিজিট করতে নিয়ে গেলেন, শহর থেকে
          কিছুটা দূরে, ভবানীগঞ্জ নামে এক এলাকায়। অনেকগুলো টিনের ঘর মিলে একটা
          মাদ্রাসা, সামনে একটা পুকুর, হৈচৈবিহীন জায়গা। মাদ্রাসার পাশেই কয়েক বিঘা
          জমি আছে, মুহতামিম সাহেবের স্বপ্ন সেগুলো কেনার ব্যবস্থা হলে বড় আকারে
          মাদ্রাসা এক্সপ্যান্ড করা, হয়তো আল্লাহ তৌফিক দিলে ভবিষ্যতে হবে। আমাদের
          অনেক আপ্যায়নও করালেন।
        </p>
        <p>
          বর্তমানে ছাত্রসংখ্যা ৩৮০, উস্তাদ ১৭ জন, বাবুর্চি ২ জন। এখন স্থান
          সংকুলন না হওয়ায় একটা ঘরের ২ পাশ বাড়াচ্ছে। আরো একটা নতুন ঘরের প্রয়োজন,
          এর পর ইনশাআল্লাহ আগামী ২ বছর কোন ঘর লাগবে না। টিনের যে স্ট্রাকচারে
          আছে, সেভাবে একটা নতুন ভবনের জন্য ১লাখ এবং ২ টা ভবনের বর্ধিত অংশের
          ফ্লোর ঢালাইয়ের জন্য আনুমানিক ৪০০০০ টাকা প্রয়োজন।{" "}
        </p>
        <p>
          যদিও সবাই জানেন, তাও আবার উল্লেখ করছি, মাদ্রাসায় সাদাক্বা করা
          সাদক্বায়ে জারিয়ার উৎস হিসেবে ওয়ান অফ দি বেস্ট। এখান থেকে যত তলিবুল
          ইলম, আলেম বের হবে, তাদের সব খেদমতের সাওয়াবের ভাগ পাওয়া যায় এবং এটা
          অনেকটা গাছের মত ছড়িয়ে পড়তে থাকবে।{" "}
        </p>
        {/*<p>
          ১ লক্ষ ৪০ হাজার মাত্র, আমরা অল্প অল্প করে দিলেও খুবই তাড়াতাড়ি হয়ে
          যাওয়া সম্ভব, আল্লাহ আমাদের হাত প্রসারিত করুন। প্রত্যাশিত অর্থ উঠলে
          প্রজেক্ট ক্লোজ করে দেয়া হবে ইনশা আল্লাহ।{" "}
        </p>*/}
        <p>মাদরাসায় দান করতে চাইলে এই মাদরাসায় করতে পারবেন ইনশাআল্লাহ।</p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <p> Nagad Number: 01711-074688 </p>
        <p> Rocket Number: 01711074688-5 </p>
        <p> bKash Number: Removed</p>
        <p>
          {" "}
          Contact: 01725-687972 (মুহতামিম সাহেব), 01711-074688 (কাওসার ভাই){" "}
        </p>
        <hr />
        <h4 className="mb-4">মারকাযে অনুদান পাঠানোর মাধ্যম:</h4>
        <p>A/C Name: Bahrul Ulum Islamia Madrasha &Yeatim Khana </p>
        <p>Bank A/C Number: 20501900205102107 </p>
        <p className="mb-4"> Bank Name: Islami Bank Bangladesh ltd.</p>
        <p>Branch: Lakshmipur Br.</p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/mahbub687`,
    projectOrgName: "Bahrul Ulum Islamia Madrasha",
    projectOrg: { _ID: "sdqmet008", name: "Bahrul Ulum Islamia Madrasha" },
    projectCreated: "August 2022",
    projectTag: "সাদক্বায়ে জারিয়া, Madrasa",
    projectLink:
      "https://www.facebook.com/photo?fbid=10159785297527110&set=pcb.10159785301267110",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  //  {
  //    _ID: "sdqmet007.1",
  //    projectImg: darularkam,
  //    projectHeading: "দারুল আরকাম ওয়াল ইসলামিয়া",
  //    slug: "darul-arkam-wal-islamia",
  //    projectDetails: (
  //      <div>
  //        <h4>সদকায়ে জারিয়ায় অংশগ্রহণ করুনঃ</h4>
  //        <p>
  //          পবিত্র এই রমজান মাস মুসলিম উম্মাহর জন্য অতি গুরুত্বপূর্ণ একটি বরকতময়
  //          মাস। এই মাস আসলে নবীজি সল্লাল্লাহু আলাইহি ওয়াসাল্লামের দানের হাত
  //          তুলনামূলকভাবে অতি দ্রুত গতিতে বৃদ্ধি পেত।
  //        </p>
  //        <p>
  //          হজরত আবদুল্লাহ ইবনে আব্বাস (রাদি.) বলেন, রাসুলুল্লাহ (সল্লাল্লাহু
  //          আলাইহি ওয়াসাল্লাম) মানুষের মধ্যে সবচেয়ে কল্যাণকামী ও শ্রেষ্ঠ দাতা
  //          ছিলেন, আর রমজান এলে তিনি সবচেয়ে বেশি দান করতেন। জিবরাঈল (আলাইহিস
  //          সালাম) এর আগমন হলে তিনি প্রবাহিত বাতাসের মতো দান–খয়রাত করতেন। [বুখারী
  //          : ৩৫৫৪; মুসলিম: ২৩০৮]
  //        </p>
  //        <p>
  //          দান সাদাকাহর আলোচনায় আল্লাহ তা'আলা বলেন, ‘যদি তোমরা দান প্রকাশ্যে করো
  //          তবে তা উত্তম; আর যদি তা গোপনে করো এবং অভাবীদের দাও, তবে তা তোমাদের
  //          জন্য শ্রেয়। এর মাধ্যমে আল্লাহ তোমাদের মন্দগুলো মোচন করে দেবেন। তোমরা
  //          যা করো আল্লাহ তা অবগত আছেন।’ [সুরা-২ বাকারা: ২৭১]
  //        </p>
  //        <p>
  //          রাসূলুল্লাহ (সল্লাল্লাহু আলাইহি ওয়াসাল্লাম) আরও বলেছেন, সদাকাহ গুনাহকে
  //          সেভাবে মিটিয়ে দেয়, যেভাবে পানি আগুনকে নিভিয়ে দেয়। [মুসনাদ আহমাদ :
  //          ২২১৩৩]
  //        </p>
  //        <p>
  //          দান-সাদাকা কিংবা যাকাত দানের মাধ্যমে সম্পদ কমেনা বরং বৃদ্ধি পায় ও
  //          বরকতময় হয় এবং আল্লাহ আযযা ওয়া জাল্লা দান-সাদাকাকে আল্লাহর কাছে উত্তম
  //          ঋণ দেওয়া হিসেবে আখ্যায়িত করেছেন। [সূরা বাকারাহ: ২৪৫, ২৭৬; সূরা
  //          হাদীস:১৮; সহীহ মুসলিম: ২৫৮৮]
  //        </p>
  //        <p>
  //          এছাড়াও রাসুলুল্লাহ (সল্লাল্লাহু আলাইহি ওয়াসাল্লাম) বলেছেন, ‘নিশ্চয়ই
  //          সদকা আল্লাহর ক্রোধকে প্রশমিত করে।’ [জামে তিরমিযী: ৬৬৪; সহীহ ইবনে
  //          হিব্বান : ৩৩০৯]
  //        </p>
  //        <p>
  //          কওমী মাদ্রাসার অর্থই হচ্ছে, জাতির মাদ্রাসা। জাতির প্রতি কওমী মাদ্রাসার
  //          যেরুপ হক রয়েছে কওমী মাদ্রাসার প্রতিও জাতির হক রয়েছে। জাতিকে দ্বীন
  //          শেখানো, তাদের নিকট দ্বীনের সঠিক ইলম পৌঁছানো, তাদের দ্বীনি চাহিদা পূরণ
  //          করা ও ইসলামের মুখলিস, দরদী, আদর্শ খাদেম তৈরি করা হচ্ছে কওমী মাদ্রাসার
  //          অন্যতম গুরুত্বপূর্ণ দায়িত্ব। কিন্তু এই দায়িত্ব আঞ্জাম দেওয়ার জন্য তারা
  //          ততক্ষণ পর্যন্ত না সক্ষম হবে যতক্ষণ পর্যন্ত মহান আল্লাহর সাহায্য ও
  //          জাতির লোকদের এই মহতী কাজে সার্বিকভাবে অংশগ্রহণ থাকবে। সেজন্য কওমী
  //          মাদ্রাসাকে সার্বিকভাবে সহায়তা-সহযোগিতা করাও জাতির আবশ্যক দায়িত্ব ও
  //          কর্তব্য।
  //        </p>
  //        <p>
  //          এরই ধারবাহিকতায় আমরা একটি কওমী মাদ্রাসার সাহায্যের জন্য আবেদন
  //          জানাচ্ছি। মাদরাসাটি বিগত দেড় বছর যাবত ঋণের বেড়াজালে আবদ্ধ রয়েছে। কতিপয়
  //          এতীম ও অসহায় ছাত্রদের বেতনও বহু মাস যাবত বকেয়া রয়েছে। অর্থের সংকোচনের
  //          কারণে সেসব শিক্ষার্থীরা বেতন ও পড়ালেখার খরচ চালাতে পারছেন না। এছাড়াও
  //          মাদরাসার কিতাব ফান্ডেও বেশ কিছু টাকার প্রয়োজন রয়েছে।
  //        </p>
  //        <p>
  //          সুতরাং সাদাকায়ে জারিয়াহর সাওয়াব হাসিলের উদ্দেশ্যে যথাসাধ্য তাওফীক
  //          অনুযায়ী আপনার/আপনাদের দান, সাদাকাহ, যাকাত, কাফফারা ও মানতের টাকা
  //          দ্বারা উক্ত মাদরাসাটির ঋণ পরিশোধে, কিতাব খরিদ করতে এবং হিফজ ও কিতাব
  //          বিভাগের মেধাবী এতীম, অসহায় শিক্ষার্থীদের সাহায্যের জন্য এগিয়ে আসুন।
  //        </p>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">
  //          দান-সাদাকাহ, যাকাত, কাফফারা ও মানতের টাকা পাঠানোর জন্য-
  //        </h4>
  //
  //        <p>নগদ পার্সোনাল 016856533432</p>
  //        <p>রকেট পার্সোনাল 01685425009</p>

  //        <hr />

  //        <p>ব্যাংক ডিটেইলস- </p>
  //        <p>DUTCH BANGLA BANK LIMITED </p>
  //        <p>HALISHAHAR BRANCH</p>
  //        <p>Mohammad Tanzim Ahmed</p>
  //        <p>Bank A/C no: 1651510142165</p>

  //        <p className="fw-bold">
  //          বিঃদ্রঃ বিকাশ, নগদ ও রকেটে টাকা পাঠালে রেফারেন্সে কোন খাতে দেওয়া হচ্ছে
  //          সেটি উল্লেখ করে দিলে ভাল হয়। যেমন, কেউ যাকাত খাতে দিলে রেফারেন্সে
  //          'যাকাত' লিখবে।
  //        </p>
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/abdullah.almamun.35175633`,
  //    projectOrgName: "Abdullah Al Mamun",
  //    projectOrg: { _ID: "sdqmet007", name: "Abdullah Al Mamun" },
  //    projectCreated: "April 2022",
  //    projectTag: "Madrasa, Sadaqah",
  //    projectLink: "https://www.facebook.com/darularqamalislamiah",
  //  },

  {
    _ID: "sdqmet009.1",
    projectImg: ummulmumenincover,
    slug: "ummul-mumenin",
    projectHeading: "উন্মুল মোমেনিন আইডিয়াল বালিকা মাদরাসা",
    projectDetails: (
      <div>
        <h4 className="mb-4">
          উন্মুল মোমেনিন আইডিয়াল বালিকা মাদ্ররাসা তাহফিজুল কুরআন ও এতিমখানা
          <br />
          ভাদালিয়া, উত্তর জলদি, বাশখালী পৌরসভা, চট্টগ্রাম স্থাপিতঃ ২০১৯ইং
        </h4>
        <p>
          আদর্শ মাতৃজাতি গঠনের একটি বিশ্বস্থ ইসলামী শিক্ষা প্রতিষ্ঠান.........
        </p>
        <p>
          * হিফজ ও নাজেরার পাশাপাশি প্রয়োজনীয় বাংলা, গণিত ও ইংরেজির পাঠদান ।
          সুন্দর হস্তলিপি আয়ত্বাকরণ ।
        </p>
        <p>আবাসিক • অনাবাসিক • ডে-কেয়ার</p>
        <p>
          নাজেরা বিভাগের বৈশিষ্ঠ্য :
          <br />
          * অভিজ্ঞ হাফেজার মাধ্যমে পাঠদান। অভিনব পদ্ধতিতে অতি অল্প সময়ে হিফজ
          সমাপ্তকরণ। বিখ্যাত কারীদের তিলাওয়াত শুনানোরব্যবস্থা। সুবিজ্ঞ কারীর
          মাধ্যমে বিশুদ্ধ ও সুন্দর তিলাওয়াতের প্রশিক্ষণ ।
          <br />
          * প্রতি মাসে পরিক্ষা ও মেধাবীদের বাছাই করে পুরস্কার প্রদান ।
          <br />* তালিম ও তারবিয়াতের গুরুত্ব প্রদান।
        </p>
        <p>
          হিফজ বিভাগের বৈশিষ্ঠ্যঃ
          <br />
          * বিশুদ্ধভাবে কুরআন পঠনের দক্ষতা অর্জন ।
          <br />
          * বিশেষ বিশেষ সূরা মুখস্থকরণ ।
          <br />
          * অযু-নামাজের মাসআলাসহ দৈনন্দিন মাসনূন দোয়াসমূহ মুখস্থ করণ ।
          <br />* প্রতিষ্ঠানের ছাত্রী ও শিক্ষিকার জন্য বিশুদ্ধ বাংলা ভাষায়
          কথোপকথন বাধ্যতামূলক ।
        </p>
        <p>
          আমরা চাই :
          <br />• একজন শিক্ষার্থী শিক্ষাগত যোগ্যতার পাশাপাশি চারিত্রিক
          সৌন্দর্য্য গঠনের মাধ্যমে বেড়ে উঠবে ।
          <br />• দ্বীনি দাওয়াত ও সমাজ সেবায় ব্রতী হয়ে উঠবে ।
          <br />• কুরআন ও সুন্নাহ অনুযায়ী নিজেও আমল করবে এবং অপরকেও তাতে
          উদ্বুদ্ধ করবে।
        </p>

        <p>পর্যায়ক্রমে কিতাব বিভাগ (দশম শ্রেণি তথা হেদায়া পর্যন্ত)</p>
        <p>সিসি ক্যামেরা দ্বারা সর্বক্ষণ নজরদারী</p>
        <div className="mt-4">
          <p>
            যোগাযোগ: জলদী মিয়ার বাজার থেকে সিএনজি ও রিক্সা যোগে ভাদালিয়া বড়
            মাদ্রাসা সংলগ্ন মহিলা মাদ্রাসা।
          </p>
          <p>মাওলানা মাহমুদ নোমানী, পরিচালক আলাপনিঃ ০১৮২৩-৯১৮৬৩৭</p>
          <p className="fw-bold">
            আমরা জাতিকে ‘সুশিক্ষিত মা’ উপহার দিতে চাই....
          </p>
        </div>
        <img
          src={ummulmumeninliflet}
          alt="ummulmumeninliflet"
          className="w-50 mb-5"
        />
        <p>মাদরাসায় দান করতে চাইলে এই মাদরাসায় করতে পারবেন ইনশাআল্লাহ।</p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <p>Bkash personal: Removed </p>
        <p>Contact: 01823-918637 </p>
      </div>
    ),
    projectFbProfile: `https://www.facebook.com/ataullahrafii`,
    projectOrgName: "বালিকা মাদরাসা ও এতিমখানা",
    projectOrg: {
      _ID: "sdqmet009",
      name: "উন্মুল মোমেনিন আইডিয়াল বালিকা মাদরাসা তাহফিজুল কুরআন ও এতিমখানা",
    },
    projectCreated: "Sep 2022",
    projectTag: "মহিলা ইয়াতিমখানা, Madrasa",
    projectLink: "https://sadaqahmadeeasy.com/madrasa/ummul-mumenin",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  {
    _ID: "sdqme022.1",
    projectImg: mahadulIman,
    slug: "mahadul-iman",
    projectHeading: "মাহাদুল ঈমান চট্টগ্রাম",
    projectDetails: `সদকায়ে জারিয়া খাত: 
অনেকে না জানার কারণে পরিচিত হয়েও সদকায়ে অংশগ্রহণ করতে পারেন না তাই বলছি....
	আমাদের মাদ্রাসায় কয়েকটি সদকায়ে জারিয়ার খাত চালু আছে বর্তমানে। তার মধ্যে...
	১. এক নাম্বার হলো মাদ্রাসার লাইব্রেরি এবং কিতাব এর জন্য সদকা করা
	২. দুই নাম্বার হলো যে সব ছাত্ররা ইনকাম করে না কিন্তু ইলম অর্জন করতে চাই তাদের পড়ালেখার খরচ বহন করা ।
	ছবিতে: লাইব্রেরীর বর্তমান অবস্থা।`,
    projectContact: `Contact: 01706-880929`,
    projectOrgName: "মাহাদুল ঈমান চট্টগ্রাম",
    projectOrg: { _ID: "sdqme022", name: "মাহাদুল ঈমান চট্টগ্রাম" },
    projectCreated: "February 2022",
    projectTag: "সাদাকা, Madrasa",
    projectLink: "https://fb.com/Mahadul-Iman-Chittagong-108827701694350",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },

  {
    _ID: "sdqme007",
    projectImg: JamiyaHafizulUlum,
    slug: "jamia-hafezul-ulum",
    projectHeading: "জামিয়া হাফেজুল উলুম আল-ইসলামিয়া",
    projectOrgName: "জামিয়া হাফেজুল উলুম আল-ইসলামিয়া",
    projectDetails: `আলহামদুলিল্লাহ আমাদের জামিয়া হাফেজুল উলুম আল-ইসলামিয়ার
  অযু ও বাথরুমের কাজ শেষ করে , পুনরায় ৯জন শিক্ষক সহ - হিফজ ও নাজেরা বিভাগের আবাসিক ঘর এবং পাশাপাশি ২০০/২৫০ জন ছাত্র শিক্ষক নামাজ আদায় করতে পারে মতো মসজিদ নির্মাণ কাজ শুরু করতে যাচ্ছি
  মাটি লেভেলিং কাজ চলছে । এতে সহযোগিতা করে যাচ্ছেন এক্সপেক্টা অব বাংলাদেশ লিঃ,মিঃ  এর উর্ধ্বতন কর্মকর্তা ইন্জিনিয়ার জনাব মোঃ নাসির উদ্দীন দিদার ও
  রাজিব কুমার দাস
  এজন্য আমরা সবাই কৃতজ্ঞ । দেশের ও জনগণের উন্নয়ন কামনা করছি
  তাই দেশে বিদেশের দ্বীন প্রিয় সকল ভাইদের নিকট বিশেষ করে  দোয়ার আবেদন করছি
  আল্লাহ তায়ালা যেন অত্র প্রতিষ্ঠানের যাবতীয় প্রয়োজন গায়েবী খাজানা থেকে পুরণ করে দেন ।
  আমীন ইয়া রাব্বুল আলামীন
  `,
    projectFbProfile1: `https://fb.com/profile.php?id=100077657504454`,
    projectFbProfile2: `https://fb.com/ahmedsagor.ctg`,
    projectContact: `বিকাশ (পার্সোনাল): Removed ||
  যোগাযোগঃ 01739-871496 (মুফতি কামরুল ইসলাম - শিক্ষক অত্র প্রতিষ্ঠান)
  `,
    projectOrg: {
      _ID: "sdqme007",
      name: "জামিয়া হাফেজুল উলুম আল-ইসলামিয়া",
    },
    projectCreated: "Januray 2021",
    projectTag: "মাদ্রাসা, Education",
    projectLink:
      "https://www.facebook.com/permalink.php?story_fbid=3025836704336789&id=100007314665018",
    get getURL() {
      return `https://sadaqahmadeeasy.com/madrasa/${this.slug}`;
    },
  },
];

export function getMadrasaList() {
  return MadrasaList;
}
