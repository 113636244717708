import ribainislam from "../img/ribainislam.jpeg";
import manageincome from "../img/manageincome.jpeg";
import sylhetcoverage from "../img/sylhetcoverage.jpeg";
import sunamgonjcoverage from "../img/sunamgonjcoverage.jpeg";
import rehabrecons from "../img/rehabrecons.jpeg";
import transgendercover from "../img/transgendercover.jpeg";
import transgenderinchart from "../img/transgenderinchart.jpeg";
import transgenderindiffer from "../img/transgenderindiffer.jpeg";

//last serial: 5

export const blogs = [
  {
    _ID: "smeblog005.1",
    name: "হিজড়া ও ট্রান্সজেন্ডার শব্দের অস্পষ্টতায় দেশে ভয়াবহ বিপর্যয়ের ঝুঁকি",
    img: transgendercover,
    slug: "disaster-transgender",
    author: "প্রফেসর মোহাম্মদ সরোয়ার হোসেন",
    details: (
      <div>
        <div>
          <p>
            সম্প্রতি ব্রিটেনের প্রধানমন্ত্রী ঋষি সুনাকের ট্রান্সজেন্ডার নিয়ে
            মন্তব্য- ‘কেউ চাইলেও লিঙ্গ পরিবর্তন করতে পারে না’ অথবা ‘পুরুষ
            পুরুষই, নারী নারীই’- বিশ্বে আলোড়ন তৈরি করে। আমরা এমন সময়ে বাস করছি
            যখন আমেরিকার মতো উন্নত দেশের সংসদে বিতর্কের বিষয় হয়- ‘পুরুষ কি গর্ভে
            সন্তান ধারণ করতে পারে?’ এ বছরের শুরুতে জেলখানায় এক ট্রান্সজেন্ডার
            নারী (জন্মগত পুরুষ) প্রকৃত নারীকে (রুমমেট) ধর্ষণের ইস্যুতে
            স্কটল্যান্ডের প্রধানমন্ত্রী পদত্যাগে (১৫ ফেব্রুয়ারি ২০২৩) বাধ্য হোন।
            স্কুলের পাঠ্যক্রমে ট্রান্সজেন্ডার বা এলজিবিটি মতাদর্শ অন্তর্ভুক্তির
            প্রতিবাদে গত ২০ সেপ্টেম্বর কানাডার লক্ষ লক্ষ (মিলিয়ন মার্চ) পিতামাতা
            রাস্তায় নেমে আসেন।
          </p>
          <p>
            আসন্ন আমেরিকার প্রেসিডেন্সিয়াল নির্বাচনে ট্রান্সজেন্ডার একটি বড়
            ইস্যু হতে যাচ্ছে। তুরষ্কের সাম্প্রতিক নির্বাচনে এলজিবিটি বড় একটি
            ইস্যু ছিল। নির্বাচনে জয়ী হওয়ার পর অত্যন্ত সংক্ষিপ্ত বক্তব্যে এরদোয়ান
            বলেন- এই বিজয় এলজিবিটি মতাদর্শের বিরুদ্ধে বিজয়। রাশিয়ার প্রেসিডেন্ট
            পুতিন ট্রাডিশনাল মূল্যবোধ রক্ষার্থে এলজিবিটির বিরুদ্ধে কঠোর অবস্থান
            নিয়েছেন। চায়নাও একই মনোভাব পোষণ করে, এলজিবিটি গোষ্ঠীর প্রাইড মাসে
            রঙধনু পতাকা বহনের অপরাধে দু’জনকে গ্রেপ্তার করে।
          </p>
          <p>
            হিজড়া এবং ট্রান্সজেন্ডার শব্দের মৌলিক পার্থক্য না বুঝার করার কারণে
            ২০১৮ সালে পাকিস্তানে ট্রান্সজেন্ডার বিল সংসদে পাস হয়। কিন্তু জন্মগত
            লিঙ্গ পরিচয়ের সাথে মনস্ত্বাত্তিক জেন্ডার পরিচয় অন্তর্ভুক্তির কারণে
            সমাজে ভয়াবহ বিপর্যয় নেমে আসবে বিধায় কোর্ট ১৭ মে ২০২৩ আইনটি বাতিল
            ঘোষণা করে। সামাজিক এবং ধর্মীয় মূল্যবোধ অক্ষুণ্ন রাখতে মধ্যপ্রাচ্য
            এবং আফ্রিকা মহাদেশের দেশগুলো এলজিবিটির বিরুদ্ধে সক্রিয় অবস্থান
            নিয়েছে। এমনকি উগান্ডা পশ্চিমা ভিসা নিষেধাজ্ঞা, বিশ্বের ব্যাংকের ঋণ
            স্থগিত করার মতো অর্থনৈতিক ব্যাপারকেও উপেক্ষা করেছে। পূর্ব ইউরোপের
            দেশগুলোও ট্রান্সজেন্ডার মতাদর্শে বিরুদ্ধে অবস্থা নিয়েছে। জেন্ডার
            আইডেন্টিটি ইস্যুতে ইতালির সরকার পরিবর্তন হয়। সম্প্রতি হ্যাংগেরি
            ট্রান্সজেন্ডাদের লিগালাইজেশন বন্ধ ঘোষণা করেছে।
          </p>
          <p>
            বিশ্বের বিখ্যাত টেক বিলিনিয়ার ইলন মাস্ক ট্রান্সজেন্ডার মতাদর্শের
            বিরুদ্ধে সরাসরি অবস্থান নিয়েছেন। এই বিষয়ে তিনি মাঝে মাঝে সোস্যাল
            মিডিয়াতে পোস্ট দিয়ে পিতামাতাকে সচেতন রাখেন। এই বিষয়টির ভয়াবহতা
            অনুধাবন করাতে সম্প্রতি তিনি একটি ভিডিও ডকুমেন্টারি (what is a woman)
            শেয়ার করেন। এক সপ্তাহের মধ্যে বিশ্বের ১৭০ মিলিয়ন মানুষ ভিডিওটি
            দেখেছে।
          </p>
          <p>
            উপরোক্ত ঘটনাগুলোয় থেকে স্পষ্টভাবে উপলব্ধি করা যায়, ট্রান্সজেন্ডার
            ইস্যুটি কেন এত গুরুত্বপূর্ণ। তা না হলে রাষ্ট্রপ্রধান ও রাজনৈতিক
            ব্যক্তিত্ব এত বেশি সোচ্চার হতেন না। এগুলো কয়েক মাস আগের ঘটনাবলি। গত
            বছর পর্যন্তও প্রেক্ষাপট ভিন্ন ছিল যখন এই মতাদর্শের বিরুদ্ধে যারা
            প্রতিবাদ করতে চেষ্টা করেছেন (একাডেমিশিয়ান, ক্লিনিসিয়ান, রিসার্চার)
            তাদের চাকরিচ্যুত করা হয়। এমনকি সন্তানের পিতা (কানাডা) এবং স্কুলের
            শিক্ষককেও (আয়ারল্যান্ড) গ্রেপ্তার করা হয়েছে ট্রান্সজেন্ডারদের
            পরিবর্তিত সম্ভোধনমূলক শব্দ (pronouns : She-এর পরিবর্তে He) ব্যবহার
            না করার অপরাধে!
          </p>
          <h3>
            জেন্ডার আইডেন্টিটি, ট্রান্সজেন্ডার ও হিজড়া শব্দ নিয়ে বিভ্রান্তি :
          </h3>
          <p>
            এই শব্দগুলো সম্পর্কে স্পষ্ট ধারণা না থাকার কারণে বিভ্রান্তি তৈরি হয়।
            যেমন বিবিসি উল্লেখ করে ‘As transgender activists acknowledge, it is
            a complex area, which can be difficult for those less than fully
            versed in a vast range of terms to negotiate.’ সেক্স এবং জেন্ডার
            শব্দ দুটি সম্পূর্ণ ভিন্ন অর্থ প্রকাশ করলেও এই ফিল্ডের বিশেষজ্ঞ ছাড়া
            প্রায় সবাই গুলিয়ে ফেলেন। সমাজ বিজ্ঞানে দুটি জেন্ডার আইডেন্টিটি (নারী
            ও পুরুষ) হিসেবে অত্যন্ত সুপরিচিত হলেও বর্তমান সময়ে এটি সম্পূর্ণ
            ভিন্ন অর্থ প্রকাশ করে। জেন্ডার শব্দটি ব্যক্তির অস্তিত্ব বা পরিচয়ের
            প্রশ্ন। বর্তমানে ১০০টির বেশি জেন্ডার আইন্ডেনিটি রয়েছে এবং এর সংখ্যা
            দিন দিন বাড়ছে।
          </p>
          <p>
            চিকিৎসাবিজ্ঞান এবং স্বাস্থ্য গবেষণায় এই শব্দ দুটোর পরিপূর্ণ অর্থ
            অস্পষ্ট থাকলে তা প্রয়োগের ক্ষেত্রে বাধা তৈরি করতে পারে। এজন্য
            আমেরিকার National Institute of Health (NIH) এই শব্দ দুটোর পার্থক্য
            বুঝাতে বিশেষ উদ্যোগ নেয় এবং ছবির মাধ্যমে তা তুলে ধরে। NIH-এর সজ্ঞা
            মতে সেক্স হচ্ছে জন্মগত বা বায়োলজিক্যাল বিষয় যেখানে ছেলে এবং মেয়ের
            দৈহিক গঠন, শারীরবৃত্তীয়, জেনেটিক এবং হরমোনগত পার্থক্য রয়েছে। অপরদিকে
            জেন্ডার হচ্ছে সামাজিক বা মনস্ত্বাতিক পরিচয় যার সাথে বায়োলজির
            উল্লেখযোগ্য সম্পর্ক নেই।
          </p>
          <img
            src={transgenderinchart}
            alt="transgenderinchart"
            className="w-100 mt-2 mb-2"
          />
          <hr />
          <p>
            এলজিবিটি ( এটি হচ্ছে গুচ্ছ শব্দ যেখানে লেসবিয়ান, গে, বাইসেক্সুয়াল,
            ট্রান্সজেন্ডার অন্তর্ভুক্ত) আন্দোলন মূলধারায় এসেছিল ১৯৫৫ সালে যখন
            সেক্স শব্দটির প্রতিভাষা হিসেবে জেন্ডার নামক শব্দ প্রবর্তনের মাধ্যমে।
            এরপর থেকে সমকামিতা ইস্যুতে অনেক শব্দ যুক্ত হয়েছে এবং প্রতিনিয়ত
            শব্দগত বিবর্তনের মধ্যে দিয়ে যাচ্ছে। শুরুতে এটা পরিচিত ছিল গে এবং
            লেসবিয়ান ইস্যু। বর্তমানে জেন্ডার আইডেন্টিটি ফিল্ডে প্রতিনিয়ত
            পরিবর্তনশীল হওয়া প্রসঙ্গে Annual Review on Law and Social Science
            নামক জার্নালে David Frank and Nolan Phillips উল্লেখ করেন- ‘The
            expansion of sexuality in society is self-reinforcing. The
            legitimation of each new identity endangers others. Thus, the old
            gay center on campus morphs into the lesbian and gay center, and
            then the LGB center, then the LGBT center and then the LGBTQ center,
            and at some point the LGBTQI center, and now even the LGBTQQIAAP
            center (lesbian, gay, bisexual, transgendered, queer, questioning,
            intersex, asexual, allies and pansexual).’
          </p>
          <p>
            জেন্ডার আইডেন্টিটি হচ্ছে একধরনের অন্তর্নিহিত বিশ্বাস বা অনুভূতি বা
            মানসিক অবস্থা (‘deeply internal sense of gender or a person's innate
            understanding of their own gender)। এটি যদি জন্মগত লৈংগিক পরিচয়ের
            সাথে সামঞ্জস্য হয় তবে তাকে সিসজেন্ডার (aligned between sex and
            gender) বলা হয়। যদি এই মানসিক অনুভূতি জন্মগত লিঙ্গের (not aligned
            between sex and gender) সাথে অমিল হয় তবে তাকে ট্রান্সজেন্ডার বলা হয়।
            একসময় ট্রান্সজেন্ডার বলতে যারা হরমোন এবং সার্জারির আশ্রয় নিতো
            তাদেরকে শুধু এই শব্দ দ্বারা বুঝানো হতো। বর্তমানে ট্রান্সজেন্ডার
            হচ্ছে আম্রেলা বা গুচ্ছ শব্দ। এটি এলজিবিটি এবং নন-বাইনারি নামক শব্দের
            সাথে ওতোপ্রোতভাবে জড়িত। কার্যত এই শব্দগুলো সমকামিতা বা
            হোমোসেক্সুয়ালিটির সাথে জড়িত। যে প্রক্রিয়ায় কোনো ট্রান্সজেন্ডার
            ব্যক্তি (ট্রান্স ম্যান বা ট্রান্স উইমেন) বাহ্যিকভাবে নিজের
            আইডেন্টিটি প্রকাশ করতে পারে তাকে ট্রান্সজিশন বলা হয়। এলজিবিটি
            মুভমেন্টের সবচেয়ে শক্তিশালী প্লাটফর্ম, GLAAD (Gay & Lesbian Alliance
            Against Defamation) ডেফিনেশন অনুযায়ী, তিনভাবে ট্রান্সজিশন বা
            রূপান্তর হতে পারে-
          </p>
          <p>
            <b>সামাজিক রূপান্তর</b>- নামের পরিবর্তন, নতুন সম্বোধন (pronouns, e.g
            they, hir), বেশভূষা পরিবর্তন, মেকাপ শুরু করা বা বাদ দেয়া (e.g nail
            polish), মেয়েদের অলংকার পরিধান শুরু করা বা বাদ দেয়- ইত্যাদির মাধ্যমে
            পরিবার, বন্ধু-বান্ধব, কলিগদের শুধু জানানোর মাধ্যমে ট্রান্সজেন্ডার
            হওয়া যায়।
          </p>
          <p>
            <b>আইনগত রূপান্তর</b>- জন্ম সনদে সেক্স আইডেন্টিটি পরিবর্তন করে
            জেন্ডার আইডেন্টিটি গ্রহন, ন্যাশনাল আইডিকার্ড পাসপোর্ট, ড্রাইভিং
            লাইসেন্স, সোস্যাল সিকিউরিটি রেকর্ড, ব্যাংক একাউন্টে নাম পরিবর্তন করা
          </p>
          <p>
            <b>মেডিকেল রূপান্তর</b>- অত্যন্ত ব্যয়বহুল হরমোন ট্রিটমেন্ট এবং
            বিভিন্ন ধরনের সার্জারি করে অবয়ব পরিবর্তন করা হয়। কিন্তু আমেরিকা এবং
            ব্রিটেনের ডাটা অনুযায়ী কমপক্ষে ৯৭% ক্ষেত্রে ট্রান্সজেন্ডারদের
            যৌনাঙ্গ (পেনিস বা যোনী) অক্ষত থাকে, যদিও তাদের শরীরের উপরে অংশ
            (মুখাবয়ব, স্তন, শারীরিক কমনীয়তা ইত্যাদি)।
          </p>
          <p>
            <b>হিজড়ারা ট্রান্সজেন্ডার নয়</b>- দেশের প্রধান মিডিয়াগুলোসহ
            বিশ্বমিডিয়া হিজড়দের ট্রান্সজেন্ডার হিসেবে প্রচার করা হয়। এমন কি এমন
            শিরোনামও করা হচ্ছে ‘বাংলাদেশে প্রথম ট্রান্সজেন্ডার মুসলিম মাদ্রাসা’,
            বাংলাদেশের প্রথম রূপান্তরকামী সংবাদপাঠিকা’- মিসলিডিং বা ভুল পথে
            পরিচালিত করতে পারে। হিজড়া একটি জন্মগত জেনেটিক সমস্যা বা ডিসঅর্ডার।
            সম্প্রতি ভারতের হিজড়া গোষ্ঠীর প্রেসিডেন্ট নিজেদের ট্রান্সজেন্ডার
            হিসেবে সংগায়িত করার জন্য প্রতিবাদ জানিয়েছে (The terms ‘Transgender’
            and Hijra are not the same’ says Joya Sikder)। আমেরিকার বিখ্যাত
            গণমাধ্যম ওয়াশিংটন পোস্টও এই বিষয়টা আলোকপাত করেছে যে হিজাড়া এবং
            ট্রান্সজেন্ডার এক নয় (Why terms like ‘transgender’ don’t work for
            India’s ‘third-gender’ communities)। অন্যদিকে এলজিবিটি বা
            ট্রান্সজেন্ডারকে কোন অসুস্থতা, ডিসওয়ার্ডার বা কোনো মানসিক সমস্যা
            হিসেবে গণ্য করা হয় না। ট্রান্সজেন্ডার এর বাংলা অভিধানিক শব্দ হিজড়া
            লেখা হচ্ছে, আবার রূপান্তরকামীও বলা হচ্ছে যা মিসলিডিং।
          </p>
          <img
            src={transgenderindiffer}
            alt="transgenderindiffer"
            className="w-100 mt-2 mb-2"
          />
          <p>
            <b>দেশে ট্রান্সজেন্ডার সামাজিকীকরণে হবে ভয়াবহ বিপর্যয় : </b>
          </p>
          <p>
            ট্রান্সজেডার নিয়ে অনেকের কাছে মনে হতে পারে, এতে সমস্যা কী, সবাই তো
            আর এক রকম হয় না। ওদের সংখ্যাই বা আর কত। তারা তো আমাদের কোনো সমস্যা
            করছে না। কিন্তু বাস্তবতা ভিন্ন। পশ্চিমা দেশগুলোতে এই মতাদর্শ পলিসি
            বাস্তবায়নের ফলে বিভিন্ন সামাজিক, স্বাস্থ্য এবং আইনগত সমস্যা গত কয়েক
            বছরে অনুধাবন করা যাচ্ছে। এটি হাজার হাজার বছরের প্রতিষ্ঠিত
            লিঙ্গভিত্তিক সিস্টেমকে ওলোট-পালট করে দিচ্ছে, তৈরি হচ্ছে নানা বিতর্ক।
            এক যুগ কম সময়ের মধ্যেই শিশু-কিশোরদের মাঝে ট্রান্সজেন্ডার আইডেন্টিটি
            নেওয়ার হার ব্যাপকভাবে বেড়ে যাচ্ছে। আমেরিকায় ২০১০ তুলনায় জেন্ডার
            ডিস্ফোরিয়া (যারা নিজেদের ভুল দেহে আটকা পড়েছে বলে মনে করে) ইস্যুতে
            (এটা মানসিক সমস্যা মনে করা হয় না) ক্লিনিকে চিকিৎসা নিতে আসা
            শিশু-কিশোরদের সংখ্যা বেড়েছে প্রায় ৫০০০%, ইংল্যান্ডের ছেলেদের মধ্যে
            বেড়েছে ১৪৬০%, আর মেয়েদের ক্ষেত্রে তা হয়েছে ৫৩৩৭%। সুইডেনে বেড়েছে
            ১৪০০% এবং ডেনমার্কে বেড়েছে ৬৭,০০০%। ২০২২ সালের সমীক্ষা অনুসারে
            আমেরিকার তরুণ প্রজন্মের (যাদের জন্ম ১৯৯৭-২০০২ সালে, এদের Z
            Generation বলা হয়) প্রায় ২১% এলজিবিটি আইডেন্টিটি গ্রহণ করেছে।
            অন্যদিকে যাদের জন্ম ১৯৬৫ সালের আগের হয়েছিল তাদের মধ্যে এটা ছিল মাত্র
            ২%। আমেরিকার এবং ব্রিটেনের তরুণ প্রজন্মের প্রায় ৪০% নিজেদের জন্মগত
            লিঙ্গ পরিচয় নিয়ে সন্দিহান বা বিশ্বাসী নন, অর্থাৎ নন-বাইনারি (এখনো
            জেন্ডার পরিচয়ে ব্যাপারে সিদ্ধান্তহীনতায় ভুগছে)। অন্যভাবে বলা যায়
            তারা এলজিবিটি এই মতাদর্শে বিশ্বাসী।
          </p>
          <p>
            <b>
              ১। উত্তরাধিকার সম্পত্তি বণ্টন নিয়ে তৈরি হবে মারাত্মক সামাজিক
              বিশৃঙ্খলা-
            </b>
            <br />
            বাংলাদেশের ট্রান্সজেন্ডার সুরক্ষা অধিকার খসড়া আইনে ট্রান্সজেন্ডারের
            ডেফিনেশন গ্লোবাল এলজিবিটি মুভমেন্টের অনুকরণে গ্রহণ করা হয়েছে।
            আত্ম-অনুভূত পরিচয়ের (self-perceived identity) ভিত্তিতে
            ‘ট্রান্সজেন্ডার’ শব্দটি ব্যবহার করা হয়েছে যা ব্যক্তির জৈবিক
            (বায়োলজিক্যাল) লিঙ্গ পরিচয়ের বিপরীত। ট্রান্সজেন্ডার শব্দের সাথে
            হিজড়া বা ইন্টারসেক্স গোষ্ঠীকে যুক্ত করার ফলে অনেকের কাছে বিষয়টি নিয়ে
            বিভ্রান্তি তৈরি হওয়া খুব স্বাভাবিক। প্রস্তাবিত খসড়া আইনের আউটলাইন
            দেখে ধারণা করা যায় এটা সাম্প্রতিক বাতিল হওয়া পাকিস্তানের
            ট্রান্সজেন্ডার সুরক্ষা বিলের সাথে প্রায় হুবুহু মিল রয়েছে। বাংলাদেশের
            খসড়া আইন (তৃতীয় চ্যাপ্টার-অধিকার সুরক্ষা- উত্তরাধিকার) অনুসারে-
            ট্রান্সজেন্ডার ব্যক্তি কতৃক অনুসৃত ধর্ম অনুসারে তাহার জন্য সম্পত্তির
            উত্তরাধিকার নিম্নবর্নিতভাবে নির্ধারণ হইবে। যথা- ক) ট্রান্সজেন্ডার
            ম্যানের জন্য উত্তরাধিকারের অংশ পুরুষের অংশের অনুরূপ হইবে; খ)
            ট্রান্সজেন্ডার উইম্যানের জন্য উত্তরাধিকারের অংশ নারীর অংশের অনুরূপ
            হইবে। আমাদের সমাজে সম্পত্তি নিয়ে বেশিরভাগ হানাহানি, মারামারি,
            বিশৃঙ্খলা হয়। দেশের প্রচলিত উত্তরাধিকার আইন ধর্মীয় মূলনীতির আলোকে
            নির্ধারিত হয়েছে। ট্রান্সজেন্ডার নিয়ে প্রস্তাবিত আইনে জন্মগত কোন মেয়ে
            নিজেকে ট্রান্সজেন্ডার পুরুষ (প্রকৃত মেয়ে) দাবি করে পুরুষের সমান
            সম্পত্তি পেতে আইনত বাধা নেই। আমাদের দেশের জন্য মতো অত্যন্ত দুর্বল
            সামাজিক এবং আইনিব্যবস্থায় এর সুদুরপ্রসারী প্রভাব কত ভয়ংকর হতে পারে
            তা অনুমেয়। জন্মগত লিঙ্গ পরিচয়কে উপেক্ষা করা হলে সামাজিক ভারসাম্য
            কার্যত ভেঙে পড়বে।
          </p>
          <p>
            <b>২। নারীরা চাকরির ক্ষেত্রে চরম বৈষম্যের শিকার হবে-</b>
            <br />
            নারীরা বিভিন্ন কারণে সামাজিক বৈষ্যমের শিকার হয়। বৈষম্য নিরসণে
            নারীদের প্রমোট করতে জব সেক্টরে নির্দিষ্ট কোটা সিস্টেম রয়েছে। যেমন
            সরকারী প্রাইমারি শিক্ষা কার্যক্রমে শতকরা ৮০ শতাংশ নারীদের জন্য
            নির্ধারিত। ট্রান্সজেন্ডার নারীরা সেই পজিশনগুলোতে প্রতিযোগিতা করতে
            আইনি বাধা থাকার কথা নয়।
          </p>
          <p>
            <b>
              ৩। ক্রীড়া-প্রতিযোগিতায়, এমনকি বিউটি কন্টেস্টেও প্রকৃত নারীরা
              বৈষম্যের শিকার হচ্ছে-
            </b>
            <br />
            অলিম্পিকের পরিবর্তিত নিয়মে ট্রান্সজেন্ডারদের সংখ্যা দিন দিন বাড়ছে।
            ২০২০ সালের টোকিও অলিম্পিকে ১৮২ জন ট্রান্সজেন্ডার অংশ নেয়, রিওডো
            জেনিরো অলিম্পিকে (২০১৬) ছিল ৫৬ এবং লন্ডন অলিম্পিকে (২০১২) অংশ নেয় ২৩
            জন। নিউজিল্যান্ডের ট্রান্স মেয়ে Laurel Hubbard ভারউত্তোলনে কলেজ
            পর্যায়ে পুরুষ হিসেবে রেকর্ও করেছিল স্থানীয় প্রতিযোগিতায় কিন্তু
            ২০১২-তে সে মেয়ে হিসেবে অলিম্পিকে অংশগ্রহন করে বিশ্ব রেকর্ড গড়ে
            কয়েকটি পদক জিতে নেয় মহিলা ইভেন্টে। ক্রিকেটার ম্যাক্সিন ব্লিথিন কেন্ট
            কাউন্টির টিমে প্রথম ট্রান্স মহিলা হিসেবে অংশ নেয়া তার ব্যাটিং গড়
            ১২৪ রান। কিন্তু পুরুষ ক্রিকেটার হিসেবে ব্যাটিং গড় ছিল মাত্র ১৫ রান!
            এ বছর মিস ইউনিভার্স প্রতিযোগিতার মূল আসরে দু’জন ট্রান্সজেন্ডার নারী
            (বায়োলজিক্যাল পুরুষ) অংশ নিচ্ছে। এই ইভেন্টের অর্গানাইজাদের প্রশ্ন
            করা হলে, তারা বলেন- Trans women are women. Full stop. অর্থাৎ
            বায়োলজিক্যাল মেয়ে এবং ট্রান্স নারীর মধ্যে পলিসি পর্যায়ে পার্থক্য
            নেই। অবাক করা বিষয় হচ্ছে যে সম্প্রতি বাংলাদেশেও দু’জন ট্রান্স নারী (
            অর্থাৎ পুরুষ) সুন্দরী প্রতিযোগিতায় অংশ নিয়েছে!
          </p>
          <p>
            <b>
              ৪। নারীরা জেলখানায়, হোস্টেল, টয়লেটে যৌন নির্যাতন এবং ধর্ষণের
              ঝুঁকিতে পড়বে-
            </b>
            <br />
            বিশ্ববিদ্যালয়ে পড়ুয়া ট্রান্সজেন্ডার নারী (অর্থাৎ জন্মগত পুরুষ) কোন
            হলে সিট পাবে? ক্যাডেট কলেজ এবং আর্মি ব্যারাকে কেউ ট্রান্সজেন্ডার
            ঘোষণা দিলে তার স্থান কোথায়ও হবে? তারা কোন কোন টয়লেট ব্যবহার? দিন দিন
            এই সমস্যাগুলো পশ্চিমা সমাজে প্রকট হয়ে উঠছে। ট্রান্সজেন্ডার হিসেবে
            মেয়েদের টয়লেট-লকার রুম ব্যবহা্র করার প্রাইভেসি নিয়ে পশ্চিমা
            দেশগুলোতে বিবৃত অবস্থায় পড়েছে প্রকৃত মেয়েরা। ব্রিটেনের মিনিস্ট্রি অব
            জাস্টিস ডিপার্টমেন্টের তথ্য অনুযায়ী- জেলখানায় ১৭৬ জন ট্রান্সজেন্ডার
            নারীর (জন্মগত পুরুষ) ৭৬ জন যৌন নির্যাতনমূলক অপরাধে জড়িত হয়। এদের ৩৬
            জন ধর্ষণ (rape is defined as penetration with penis) এবং ১০ জন
            ধর্ষণের প্রচেষ্টার জন্য দোষী সাব্যস্ত হয়। এখানে যে বিষয়টা জানা
            গুরুত্বপূর্ণ তা হচ্ছে ৯৭% (ব্রিটেন এবং আমেরিকার ডাটা) এর বেশি
            ট্রান্সজেন্ডার নারীতে পুরুষাঙ্গ থাকে।
          </p>
          <p>
            <b>৫। মারাত্মক জনস্বাস্থ্য সমস্যা তৈরি হবে-</b>
            <br />
            শারীরিকভাবে রূপান্তরের (মূলত বাহ্যিক) জন্য হরমোন চিকিতসা করা হয়।
            European Journal of Endocrinology নামক বিখ্যাত জার্নালে প্রকাশিত
            সাম্প্রতিক গবেষণার তথ্যানুসারে হরমোন চিকিৎসা নেওয়া ট্রান্সজেন্ডার
            নারীদের ৯৫% এর হৃদরোগের ঝুঁকি বেশি। ৫ বছর মেয়াদি ২৬৭১ ট্রান্সজেন্ডার
            নারীর নিয়ে ডেনমার্কে স্টাডিটি পরিচালিত। অন্যদিকে ট্রান্সজেন্ডারদের
            ডায়াবেটিসের ঝুঁকিও বেশি। পিউবার্টি ব্লকার এবং শরীরে বিভিন্ন ধরনের
            সার্জারির কারণে চিরস্থায়ীভাবে বন্ধাত্ববরণসহ বিকলাঙ্গ হওয়া হয়।
            স্বাভাবিক সেক্সুয়াল প্র্যাক্টিশে অভ্যস্ত মানুষের তুলনায় এলজিবিটি
            কমিউনিটিতে এইচআইভিতে সংক্রামিত হওয়ার ঝুঁকি কমপক্ষে ২৬ থেকে ৩০ গুণ
            বেশি। মাংকিপক্স ভাইরাসে আক্রান্তদের ৯৫% এর বেশি এলজিবিটি (সমকামী
            পুরুষ)-এ দেখা গেছে। তাছাড়া বিভিন্ন ধরনের ক্যান্সার (যেমন সিডিসির
            ডাটা অনুযায়ী এনাল ক্যান্সার সম্ভাবনা সমকামীদের ১৭ গুণ বেশি) হওয়ার
            ঝুঁকি সাধারণ জনসাধারণের তুলনায় বেশি যা বিভিন্ন গবেষণা উঠে এসেছে।
            আমেরিকায় সিফিলিস, গনেরিয়ার ৮৩% সমকামী কমিউনিটিতে দেখা যায়।
            <br />
            ট্রান্সজেন্ডার স্বাভাবিক সুস্থ মানুষ হিসেবে নিজেদের মনে করলেও বা
            সমাজে উপস্থাপন করলেও তারা অনেক মানসিক সমস্যায় জর্জরিত হয়। গবেষণায়
            দেখা গেছে সাধারণ মানুষের তুলনায় ১৪ গুণ বেশি আত্মহত্যা চিন্তা এবং ২২
            গুণ আত্মহত্যা প্রচেষ্টা নেয়। তাছাড়া মাঝে মাদকাসাক্ত, নিজে নিজের
            ক্ষতি করা (self-harm), ডিপ্রেশন, উদ্বিগ্নতা ইত্যাদির প্রবণতাও অনেক
            বেশি। এলজিবিটি কমিউনিটি তাদের এই মানসিক যাতনা জন্য দায়ী করে পরিবার
            এবং সমাজের অবজ্ঞা এবং অবহেলাকে। কিন্তু চরম বাস্তবতা হচ্ছে সমাজের
            হাজার বছরের প্রতিষ্ঠিত সুস্থ এবং স্বাভাবিক রীতি-নীতি, আইনকানুন
            আবেগবশত উপেক্ষা করলে মানসিক চাপ হওয়া অস্বাভাবিক নয়। এ অবস্থায়
            ট্রান্সজেন্ডার বা এলজিবিটি সামাজিকীকরণ হলে বাংলাদেশের মতো স্বপ্ল
            রিসোর্স সম্বলিত স্বাস্থ্য ব্যবস্থায় অতিরিক্ত বোঝা তৈরি হবে।
          </p>
          <h3>বাংলাদেশের ট্রান্সজেন্ডার অধিকার আইন করা হবে আত্মঘাতী :</h3>
          <p>
            জন্মগত লিঙ্গ পরিচয়ের ভিত্তিতে আমাদের জন্ম সনদ, জাতীয় পরিচয়পত্র (NID)
            ও পাসপোর্ট দেয়া হয়। এগুলো উত্তরাধিকার সম্পত্তি বণ্টন, ব্যাংকের
            নমিনি, মালিকানা ইত্যাদির সাথে সম্পর্কিত। পশ্চিমা দেশগুলোতে এসব
            ডকুমেন্টে লিঙ্গ পরিচয় নাকি জেন্ডার আইডেন্টিটি থাকবে তা নিয়ে ব্যাপক
            রাজনৈতিক, সামাজিক এবং একাডেমিক বিতর্ক হচ্ছে। কিন্তু আমাদের দেশে গভীর
            চিন্তা-ভাবনা এবং বিশ্লেষণ ছাড়াই ব্যক্তিগত পর্যায়ে প্রভাবিত হয়ে এমন
            সিদ্ধান্ত নিয়ে ফেলে যা প্রজন্ম থেকে প্রজন্ম ভোক্তভোগী হতে পারে।
            প্রসংগত, দেশের পাসপোর্টে লিঙ্গ পরিচয় উঠিয়ে জেন্ডার শব্দ যুক্ত করা
            হয়েছে। স্বাস্থ্যসংক্রান্ত সরকারি ডকুমেন্টে সেক্স শব্দ উঠিয়ে ইদানীং
            জেন্ডার শব্দ ব্যবহার করা শুরু হয়েছে।
          </p>
          <p>
            গত ২৩ ফেব্রুয়ারি ২০২৩ তারিখে ‘সমকাল’-একটি প্রতিবেদন প্রকাশ করেছে যা
            শংকিত করার মতো বিষয়। পত্রিকাটি জানায় - ‘ঢাকাস্থ নেদারল্যান্ডস
            দূতাবাসের রাজনৈতিকবিষয়ক প্রথম সচিব কর স্টৌটেন বলেন, বাংলাদেশ সরকার
            ট্রান্সজেন্ডার এবং তৃতীয় লিঙ্গ সম্প্রদায়ের মানুষকে জাতীয় পরিচয়পত্র
            ও পাসপোর্টে স্বীকৃতি দিয়েছে। বাংলাদেশ এক্ষেত্রে বিশ্বের অধিকাংশ দেশ
            থেকে এগিয়ে আছে। এটা খুবই প্রশংসনীয়। নো পাসপোর্ট ভয়েস এর
            ট্রান্সজেন্ডার অধিকারবিষয়ক শুভেচ্ছা দূত হো চি মিন ইসলাম বলেন,
            বাংলাদেশ বিভিন্ন ক্ষেত্রে ভারত, পাকিস্তানসহ দক্ষিণ এশিয়ার অনেক দেশ
            থেকে এগিয়ে আছে।’ – এই খবরটি যদি সত্যি হয় তবে দেশে সুদূরপ্রসারী ভয়াবহ
            প্রেক্ষাপট তৈরি হবে। ট্রান্সজেন্ডার সুরক্ষা খসড়া আইন পাস করা হলে
            ট্রান্সজেন্ডার আইডেন্টিটি স্বীকৃতি দেয়া হবে এবং এর মাধ্যমে
            প্রকান্তরে দেশে সমকামিতা আইনত বৈধ হতে বাধা থাকার কথা নয়।
          </p>
          <p>
            প্রধানমন্ত্রী মার্চ ২০২৩-এ সিএনএন কে দেওয়া এক সাক্ষাৎকারে সামাজিক
            প্রেক্ষাপটে এলজিবিটি আইন্ডেন্টিটি স্বীকৃতি দেওয়ার বিপক্ষে বলে
            মন্তব্য করেন। ১৩ এপ্রিল ২০২২ সালে এই পররাষ্ট্র প্রতিমন্ত্রী এই বিষয়ে
            শক্তিশালী বিবৃতি দিয়েছেন। এলজিবিটি আমাদের ইসলাম ধর্মের পরিপন্থি
            পররাষ্ট্র প্রতিমন্ত্রী যুগান্তর পত্রিকায় একটি প্রতিবেদন প্রকাশিত হয়।
            সেখানে পররাষ্ট্র প্রতিমন্ত্রী বলেন- এলজিবিটিদের (লেসবিয়ান, সমকামী,
            রূপান্তরকামী) জন্য বাংলাদেশে আইন নেই এবং বাংলাদেশ তাদের প্রয়োজন
            মেটাতে পারছে না- এমন কথাও বলা হয়। এ বিষয়ে শাহরিয়ার আলম বলেন, এটা
            আমাদের ইসলাম ধর্মের পরিপন্থি। পৃথিবীর এমন একটা মুসলিম দেশ দেখান যারা
            এলজিবিটিকে অনুমোদন দেয়। যত দেশ বা সংস্থা থেকে চাপ আসুক না কেন
            এলজিবিটি প্রশ্নে কোনো ছাড় দেবে না বাংলাদেশ। এটা বাংলাদেশের মানুষের
            সঙ্গে বিরোধিতা করা হবে, ধর্মের সঙ্গে বিরোধিতা করা হবে। এ থেকে
            স্পষ্টত প্রতিয়মান হয়, ট্রান্সজেন্ডারিজম বাংলাদেশে ধর্মীয়ভাবে অত্যন্ত
            সংবেদনশীল এবং আইনগতভাবে নিষিদ্ধ। জোর করে এই মতাদর্শ আইনগতভাবে চাপানো
            হলে দেশে ধর্মীয় উগ্রবাদ উসকে দেয়া হবে।
          </p>
          <p>
            বাংলাদেশের সাধারন মানুষ (সম্ভবত পলিসিমেকাররাও) হিজড়াকে
            ট্রান্সজেন্ডারের সমার্থক মনে করেন। হিজড়ারা সমাজে অত্যন্ত অবহেলিত। যে
            কারোর সন্তান জন্মগত ত্রুটির কারনে হিজড়া হয়ে জন্ম নিতে পারে। এদের
            সামাজিকীকরণ করা রাষ্ট্রের দায়িত্ব। সরকার ইতিমধ্যে তাদেরকে তৃতীয়
            লিঙ্গ হিসেবে স্বীকৃতি দিয়েছে। প্রায় দুই যুগ ধরে অবহেলিত হিজড়াদের
            সহানুভূতিকে পুঁজি করে ট্রান্সজেন্ডার মতাদর্শ প্রতিষ্ঠা করার
            প্রচেষ্টা দেশের জন্য এক ভয়ংকর পরিনতি ডেকে নিয়ে আসবে। প্রতি ৫০০০ জনে
            সর্বোচ্চ একজন হিজড়া হিসেবে জন্ম নিতে পারে। সেই হিসেবে বাংলাদেশে
            সর্বোচ্চ ৩০-৩৫ হাজার হিজড়া থাকতে পারে। ধর্মীয় দিক থেকেও হিজড়াদের
            পাশে দাঁড়ানো গুরুত্বপুর্ণ সামাজিক দায়িত্ব। ডায়াগনোস্টিক পরীক্ষার
            (জেনেটিক এবং বায়োকেমিকেল) মাধ্যমে লিঙ্গ পরিচয় শনাক্ত করে হিজড়াদের
            পড়ালেখা, আবাস ও কর্মসংস্থান তৈরিতে পদক্ষেপ গ্রহণ হবে যাতে তারা দেশের
            দক্ষ জনবল হয়ে গড়ে ওঠে। আইনের মাধ্যমে হিজড়া সম্প্রাদায়ের অধিকার
            নিশ্চিত হওয়া এবং হিজড়ার নামে ট্রান্সজেন্ডারিজম যেন এ দেশে প্রতিষ্ঠিত
            না হয় সেদিকে সচেতন হওয়া জরুরি।
          </p>
          <p>
            <b>মোহাম্মদ সরোয়ার হোসেন, পিএইচডি </b>: সহযোগী অধ্যাপক, আইইউবি;
            সেক্স চেঞ্জ ফিল্ডে ন্যাশনাল ইউনিভার্সিটি অব সিংগাপুর-এ ৬ বছরব্যাপী
            পিএইডি গবেষণা করেছেন, দেশে ১১ বছর যাবত জনস্বাস্থ্য নিয়ে গবেষণা
            করছেন। এই আর্টিকেলের রেফারেন্সের জন্য এই গবেষকের লিখিত বই- ‘সন্তান
            প্রতিপালনে এ যুগের চ্যালেঞ্জ’ পড়তে পারেন।
          </p>
          <h4 className="mt-4 bg-white shadow-lg text-dark p-3 rounded">
            {" "}
            এই বিষয়ে মোহাম্মদ সরোয়ার হোসেন স্যারের আরো লেখা পড়তে ভিসিট করুনঃ{" "}
            <a
              href="https://mshossain.com/category/lgbt/"
              target="__blank"
              className="text-primary"
            >
              {" "}
              mshossain.com{" "}
            </a>
          </h4>
        </div>
      </div>
    ),
  },
  {
    _ID: "smeblog003.1",
    name: "পুনর্বাসন নাকি পুনর্গঠন? কোনটা",
    img: rehabrecons,
    slug: "rehab-recons",
    author: "Team SME",
    details: (
      <div>
        <div>
          <p>
            স্মরণকালের সবচেয়ে ধ্বংসাত্মক বন্যা আমরা দেখেছি সিলেটে, যেটির সময়কাল
            ২০২২ সালের মে এবং জুন মাস। টানা বৃষ্টির কারণে সুনামগঞ্জের নতুন করে
            বন্যার শঙ্কা এখনো কাটে নি। সিলেট জেলার বন্যা পরিস্থিতি উন্নতি হচ্ছে
            খুবই ধীরগতিতে।
          </p>
          <p>
            তবে উন্নতি হয়েছে সিলেট জেলার কোম্পানিগঞ্জ, গোয়াইনঘাট, জৈন্তাপুর ও
            কানাইঘাটের বন্যা পরিস্থিতি। আমরা যদি ধরেই নি যে, যারা পুনর্বাসন বা,
            গৃহনির্মাণ নিয়ে ইতিমধ্যে পরিকল্পনা শুরু করে দিয়েছেন(আমাদের
            প্ল্যাটফর্মে ইতিমধ্যে একটু যুক্ত করা আছে) তারা শুরুতে সিলেটের
            কোম্পানিগঞ্জ, গোয়াইনঘাট, জৈন্তাপুর ও কানাইঘাট হয়ে কাজ শুরু করবেন,
            তাহলে তাদের জন্য আজকে এই পোস্ট। হতে পারে আরো কিছু থানাধীন অঞ্চলে
            বন্যা পরিস্থিতি উন্নতির দিকে যাচ্ছে এবং লোকাল সংস্থাগুলো পুনর্বাসন
            প্রকল্প শুরু করার পথ, এমন সংস্থাগুলোর জন্যেও ইনশাআল্লাহ এই লেখাটি
            উপকারে আসতে পারে।
          </p>
          <p>
            পরিকল্পনার শুরুতেই টীমকে সিদ্ধান্ত নিতে হবে তারা কি Rehabilitation
            বা, পুনর্বাসন নিয়ে কাজ করবে নাকি Reconstruction বা, পুনর্গঠন নিয়ে
            কাজ করবে। জানা প্রয়োজন এই দুইয়ের মধ্যকার পার্থক্য (যেটি মূলত
            Disaster Management এর একদমই প্রাথমিক পাঠ):
          </p>
          <b>Rehabilitation বা, পুনর্বাসনঃ</b>
          <br />
          <br />
          <p>
            Rehabilitation বা, পুনর্বাসন বলতে বন্যার (এই ক্ষেত্রে অধিক বোধগম্য
            হতে "দূর্যোগ" ব্যবহার না করে আমরা "বন্যা" ব্যবহার করছি ) পরে গৃহীত
            পদক্ষেপগুলোকে বোঝায় যাতেঃ
          </p>
          <p>
            • ক্ষতিগ্রস্থদের স্বাভাবিক কার্যকলাপ পূর্বের ন্যায় শুরু করতে
            <br />• শারীরিকভাবে ক্ষতিগ্রস্থদের চিকিৎসার ব্যবস্থা করতে •
            জনসাধারণের প্রাপ্য সুবিধাগুলো পুনরূদ্ধার করতে • মানসিক ও সামাজিক
            উন্নতিসাধনের জন্য সহায়তা প্রদান করতে মৌলিক সেবাগুলো (basic
            services) পুনরায় চালুর ব্যবস্থা করা যায়।
          </p>
          <p>
            Rehabilitation বা, পুনর্বাসন প্রকল্প মৌলিকভাবে বন্যায় ক্ষতিগ্রস্থদের
            ঠিক বন্যা-পূর্ববর্তী (Pre-flood state) জীবনধারায় নিয়ে যাওয়াকে ফোকাস
            করে। সহজে বলা যেতে পারে "জরুরী ত্রাণ প্রয়োজন" এবং "ক্ষতিগ্রস্থদের
            দীর্ঘমেয়াদী উন্নয়ন" এর ঠিক ক্রান্তিকালীন অবস্থানে বা, Transitional
            phase এসে Rehabilitation বা, পুনর্বাসন প্রকল্প বাস্তবায়নযোগ্য হয়।
          </p>
          <br />
          <b>Reconstruction বা, পুনর্গঠন: </b>
          <br />
          <br />
          <p>
            Reconstruction বা, পুনর্গঠন বলতে এমন পদক্ষেপগুলোকে বোঝায় যার ফলেঃ
          </p>
          <p>
            • জনগণ তার প্রাপ্য সেবাগুলো আবারো পাওয়া শুরু করবে
            <br />• স্থানীয় অবকাঠামোর সম্পূর্ণ পুনরুদ্ধার হবে • ক্ষতিগ্রস্থ
            বস্তুগত উপাদানসমূহ পুরোপুর প্রতিস্থাপন হবে যার ফলে অর্থনীতির
            পুনরুজ্জীবন এবং সামাজিক কৃষ্টিকালচার পুনরুদ্ধার সম্ভব হবে।
          </p>
          <p>
            Reconstruction এবং Rehabilitation মধ্যে তেমন কোনো পার্থক্য মোটাদাগে
            এখনো প্রকাশ না পেলেও মূল পার্থক্য কিন্তু এক কথায় প্রকাশ করা যায়ঃ
            Reconstruction বা, পুনর্গঠন প্রকল্প অবশ্যই দীর্ঘমেয়াদী এবং টেকসই
            উন্নয়ন পরিকল্পনাকে সামনে রেখেই করতে হবে যাতে ভবিষ্যতে বন্যা বা,
            দূর্যোগের ঝুঁকি এবং সম্ভাবনাকে যথাযথ ব্যবস্থা গ্রহণের মাধ্যমে কমানো
            যায়।
          </p>
          <p>
            বন্যায় যারা বাসা-বাড়ি হারিয়ে নিঃস্ব তাদের জন্য জরুরী অবস্থায়
            অস্থায়ী আশ্রয় কেন্দ্র অথবা, ছোট ভাসমান থাকার ঘর নির্মাণ হল
            Rehabilitation। আর বন্যা পরবর্তী পরিস্থিতিতে তাদেরকে অস্থায়ী আশ্রয়
            কেন্দ্র থেকে যথাযথ টেকসই বাসস্থান নির্মাণ করে তাতে ফিরিয়ে আনা হল
            Reconstruction, এর সাথে উপরে উল্লিখিত বিষয়গুলোও পুনরুদ্ধার করতে
            সর্বোচ্চ চেষ্টা করতে হবে।
          </p>

          <p>
            আমাদের এতো কিছু বিস্তারিত লেখার কারণ কী? আমাদের উদ্দেশ্য হল যেসকল
            সংস্থা সিলেটে কিংবা, অন্যান্য অঞ্চলে মাঠ পর্যায়ে কাজ করছে তাদের
            প্রতিটি প্রকল্প প্রচার এবং বাস্তবায়নে যথাযথ "শব্দের" ব্যবহারে সহায়তা
            করা।
          </p>
          <p>impact = idea X implementation</p>
          <p>
            উদ্দেশ্য আর বাস্তবায়নের সাথে যদি কাজের প্রভাবের মিল ঘটাতে চাই, তাহলে
            প্রয়োজন উক্ত কাজ সম্পর্কে মৌলিক ধারণা। যেটি প্রচারে Team SME
            সর্বোচ্চ চেষ্টা করছে।
          </p>
          <p>সম্পূর্ণ পড়ার জন্য জাযাকাল্লাহু খাইর। </p>
        </div>
      </div>
    ),
  },

  {
    _ID: "smeblog-1.1",
    name: "আল্লাহ্‌র মনোনীত জীবন ব্যবস্থায় সুদ",
    img: ribainislam,
    slug: "riba-in-islam",
    author: "নাজমুস সাকিব",
    details: (
      <div>
        <div>
          <h4 className="fw-bold">পর্ব - ১</h4>
          <p>বিসমিল্লাহির রাহমানির রাহিম</p>
          <p>
            ইসলাম একটি পূর্ণাঙ্গ জীবন ব্যবস্থা - এই বিষয়টা প্রতিটি মুসলিমই কম
            বেশি উল্লেখ করে থাকেন, বলে থাকেন, লিখে থাকেন। কিন্তু বুঝে থাকেন কজন,
            সে বিষয়ে যথেষ্ট সন্দেহ পোষণ করার সুযোগ রয়ে যায়। কারণ জীবন ব্যবস্থা
            বা লাইফ সিস্টেমের কিছু সংজ্ঞা ও বৈশিষ্ট্য রয়েছে, যা ইসলামকে জীবন
            ব্যবস্থা বলে দাবি করার সাথে সাথে অনুধাবন ও অনুসরণ করা জরুরী। অল্প
            ভাষায় বিস্তর বলা সম্ভব না হলেও যা উল্লেখ করা সম্ভব, তা হল যে কোন
            জীবন ব্যবস্থাই একজন ব্যক্তির ব্যক্তিগত বা সমষ্টিগত জীবনে কিছু কোড বা
            নিয়ম কানুন সেট করে দিয়ে থাকে। অর্থাৎ ব্যক্তিগত জীবন যাপনের নিয়ম,
            পারিবারিক জীবন, রাষ্ট্রীয় জীবন, সামাজিক জীবন, অর্থনৈতিক জীবন ইত্যাদি
            নানা ক্ষেত্রে কিছু গৃহীত নিয়ম বা এক্সেপ্টেড নর্মস সেই জীবন ব্যবস্থায়
            ডিফল্ট হিসেবে থেকে থাকে। তাই ইসলামকে আমরা যখন জীবন ব্যবস্থা রূপে
            ঘোষণা করছি, তখন আমাদের বুঝে থাকা জরুরী, যে এইসব উপরোল্লিখিত বিষয়ে
            ইসলামের কোন নিয়ম, পরামর্শ কিংবা গাইডলাইন আছে কিনা।
          </p>
          <p>
            এক্ষেত্রে, আল্লাহ্‌ সুবহানাহু ওয়া তা’লা কুরআনে উল্লেখ করেছেন,  
            “নিশ্চই আল্লাহ্‌র নিকট (গ্রহণযোগ্য/অনুমোদিত) জীবন ব্যবস্থা হল ইসলাম”
            [সূরাহ আলি ইমরান: আয়াত ১৯]
          </p>
          <p>
            অর্থাৎ, বিচিত্র ধারার অসংখ্য জীবন ব্যবস্থাসমূহের মাঝে, আল্লাহ্‌
            তা’লা কেবল মাত্র একটি জীবন ব্যবস্থাকেই রিকগনাইজ করেন। অন্যভাবে বলতে
            গেলে, আল্লাহ্‌র কাছে দাম রাখে, গ্রহণযোগ্যতা রাখে শুধুমাত্র একটি জীবন
            পন্থা। আর তা হল ইসলাম।   অন্য আয়াতে আল্লাহ্‌ বলছেন,   “যে কেউ ইসলাম
            ছাড়া অন্য জীবন ব্যবস্থা খোঁজ করবে, কোনদিনই তা গ্রহণ করা হবে না এবং
            আখিরাতে সে ক্ষতিগ্রস্তদের অন্তর্ভুক্ত” [সূরাহ আলি ইমরান: আয়াত ৮৫]
          </p>
          <p>
            অর্থাৎ ইসলাম ছাড়া আর কোন জীবন ব্যবস্থা মেনে চলা ব্যক্তির আখিরাতে
            ক্ষতি ব্যতীত কিছু পাওনা নেই। তার সেই জীবন ব্যবস্থা আল্লাহ্‌ কখনই,
            কোন অবস্থাতেই গ্রহণ করবেন না।
          </p>
          <p>
            যারা ইসলামকে সত্যিকার অর্থেই গুরুত্বের সাথে নিয়েছেন, তারা জানেন,
            ইসলাম মানব জীবনের কোন একটি স্তর, পর্যায় কিংবা ক্ষেত্র বাকি রাখে নি,
            যেখানে আল্লাহ্‌ প্রদত্ত কোন গাইড লাইন প্রদান করা হয় নি। কিভাবে নখ
            কাটতে হয় থেকে শুরু করে কিভাবে শ’খানেক রাজ্যকে একজন শাসকের অধীনে নিয়ে
            এসে সমস্ত পৃথিবী শাসন করতে হয় পর্যন্ত সমস্ত বিষয়ে ইসলাম দেখিয়েছে পথ।
            জ্বেলেছে আলোকবর্তিকা।
          </p>
          <p>
            তাই বলা বাহুল্য, ইসলামের একটি অর্থনৈতিক দর্শনও রয়েছে। রয়েছে
            অর্থনৈতিক ব্যবস্থা। ইসলামের বিভিন্ন শাখা প্রশাখার মধ্যে অন্যতম বিশদ,
            বিস্তর ও সুবিশাল শাখা হল ইসলামি অর্থনীতি। একজন মুসলিম হিসেবে, আমাদের
            অতি অবশ্যই ইসলামের অর্থনৈতিক মৌলিক তত্ত্ব, বাস্তবিক প্রয়োগ, সমাধান ও
            নির্ধারিত নিয়ম কানুন সম্পর্কে অবহিত হওয়া এবং অন্যান্য প্রচলিত
            অর্থনৈতিক পদ্ধতির সাথে ইসলামি অর্থনীতির পার্থক্য সম্পর্কে অবহিত হওয়া
            ও হারাম হালাল সম্পর্কে সজাগ থাকা জরুরী। আমরা যেমন মুসলিম হিসেবে
            খৃষ্টানদের রীতিতে আল্লাহ্‌র ইবাদত করতে পারি না কিংবা বৌদ্ধদের রীতিতে
            বিয়ে করতে পারি না, ঠিক সেভাবেই আমরা ইসলাম ব্যতীত অন্য কোন নিয়মে
            অর্থনৈতিক কার্যক্রম সমাধা করতে পারি না, যদি তা ইসলামের সাথে মৌলিক
            ভাবে সাংঘর্ষিক হয়ে থাকে।
          </p>
          <p>
            ইসলামের অর্থনৈতিক ব্যবস্থার গোড়াপত্তন হয় কুরআন ও সুন্নাহ দ্বারা। এই
            কুরআন সুন্নাহর নির্দেশনা অনুসারে ইসলামি অর্থ ব্যবস্থা পরিচালিত হয়।
            সেই রাসূল [ﷺ] এর রাষ্ট্র থেকে শুরু করে খিলাফত রাষ্ট্র পর্যন্ত
            অর্থনীতি পরিচালিত হয় ইসলামি অর্থনীতির মাধ্যমে। খিলাফতের পতনের পর
            থেকে অবধারিত ভাবে ইসলামের অর্থনৈতিক ব্যবস্থার প্রজ্বলিত আলোও স্তিমিত
            হয়ে আসে। ফলে দীর্ঘ শত বছর ধরে মুসলিম ভূমিগুলোতে অর্থ ব্যবস্থা দখল
            করে নিয়েছে প্রথমত সমাজতান্ত্রিক ও পরবর্তীতে পুঁজিবাদী অর্থ ব্যবস্থা।
            উভয়ের মৌলিক ধারনাই ইসলামের ঘোর বিরোধী। কিন্তু এই সুদীর্ঘ সময়ে মুসলিম
            জাতি এইসব মানবসৃষ্ট অর্থনৈতিক প্রথা ও ব্যবস্থায় নিমজ্জিত হওয়ার দরুন
            ইসলামের মৌলিক অর্থনৈতিক ব্যবস্থা সম্পর্কে বেখবর হয়ে পড়েছে। ফলে
            ইসলামি অর্থনীতির প্রসঙ্গ তাদের কাছে নতুন কোন ফিলোসফি রূপে ধরা দেয়,
            অথচ এটা সেই ব্যবস্থা যা শত শত বছর ধরে কার্যকররূপে প্রচলিত ছিল এবং
            বর্তমান ক্যাপিটালিস্টিক ইন্টারেস্ট বেইসড সমাজের মত এর মেরুদন্ড কখনই
            ভেঙ্গে পড়ে নি।
          </p>
          <p>
            অর্থনীতির মৌলিক উদ্দেশ্যের ব্যাপারে প্রখ্যাত মার্কিন
            অর্থনীতিবিদ Richard Theodore Ely বলেন,     "Economics is a science,
            but something more than a science, a science that though with the
            infinite variety of human life, calling not only for systematic,
            ordered thinking , but human sympathy, imagination, and in an
            unusual degree for the saving grace of commonsense."  
          </p>
          <p>
            অর্থাৎ, অর্থনীতি কেবল একটি বিজ্ঞানই নয়, এটি কেবলমান্ত সুসংবদ্ধ,
            সুশৃঙ্খলিত চিন্তার আবেদনই জানায় না, মানুষের প্রতি সহানুভূতির উদ্রেক
            করতে ও বাস্তব জ্ঞান অসাধারণ পরিমান সম্প্রসারণ করতেও তা সচেষ্ট। 
          </p>
          <p>
            রাজনৈতিক অর্থনীতির জনক নামে খ্যাত স্কটিশ অর্থনীতিবিদ Adam
            Smith বলেছিলেন    "Economics is the science of wealth."   অর্থাৎ
            অর্থনীতি হল সম্পদের (বন্টন) বিজ্ঞান।
          </p>
          <p>
            অর্থনীতি বিজ্ঞানে প্রথম নোবেল মেমোরিয়াল পুরস্কার প্রাপ্ত মার্কিন
            অর্থনীতিবিদ Paul A. Samuelsonএর মতে,   "Economics is the study of
            how men and society choose, with or without the use of money, to
            employ scarce productive resources which could have alternative
            uses, to produce various commodities over time and distribute them
            for consumption now and in the future amongst various people and
            groups of society."  
          </p>
          <p>
            অর্থাৎ স্বল্প সম্পদ কি করে অর্থ ব্যবহার করে কিংবা না করে সমাজের
            বিভিন্ন শ্রেণীর মানুষের মাঝে বন্টন করা যায়, সে সংক্রান্ত বিদ্যাই হল
            অর্থনীতি।
          </p>
          <p>
            অর্থনীতির ব্যপারে যে মৌলিক বিষয়টা বোঝা জরুরী, তা হল সম্পদের সুষম
            বন্টন নিশ্চিত করণ, যাতে আপাত অল্প ও সীমাবদ্ধ সম্পদ দ্বারা জনগণের
            চাহিদা পূরণ করা সম্ভব। শুধু তাই নয়, সম্পদের সুষম বন্টন নিশ্চিতকরণের
            অর্থ এই, যে তা যেন সমাজের বিশেষ কোন অংশের হাতের মুঠোয় পুঞ্জিভূত না
            হয়ে পড়ে।  
          </p>
          <p>
            যদিও অর্থনীতির সংজ্ঞা বা তত্ত্বসমূহ শুনতে অনেক সহজ আর বাস্তবিক মনে
            হয়, পুঁজিবাদ ভিত্তিক ভোগবাদী সামাজিক ব্যবস্থায় তা কোন ভূমিকা রাখতে
            পারে না। বর্তমান সুদ ভিত্তিক অর্থনৈতিক ব্যবস্থা কোন অবস্থাতেই
            সম্পদের সুষম বন্টন নিশ্চিত করতে সক্ষম হয় নি, হওয়ার সম্ভাবনাও ক্ষীণ।
          </p>
          <p>
            অথচ ইসলামের অর্থনৈতিক দর্শন সম্পদের সুষম বন্টনকে কেন্দ্র করে
            প্রতিষ্ঠিত। ইসলামি অর্থনীতির প্রাথমিক লক্ষ্য সমাজের দুটি প্রান্তকে
            একীভূত করা। একের সম্পদে অন্যের যে অধিকার, তা নিশ্চিত করা। আল্লাহ্‌
            তা’লা বলেন,     আল্লাহ জনপদবাসীদের কাছ থেকে তাঁর রসূলকে যা দিয়েছেন,
            তা আল্লাহর, রসূলের, তাঁর আত্নীয়-স্বজনের, ইয়াতীমদের, অভাবগ্রস্তদের
            এবং মুসাফিরদের জন্যে, যাতে ধনৈশ্বর্য্য কেবল তোমাদের বিত্তশালীদের
            মধ্যেই পুঞ্জীভূত না হয়। করবে। [সূরাহ হাশর: আয়াত ৭]
          </p>
          <p>
            ঈমানদারগণ! তোমরা একে অপরের সম্পদ অন্যায়ভাবে গ্রাস করো না।
            কেবলমাত্র তোমাদের পরস্পরের সম্মতিক্রমে যে ব্যবসা করা হয় তা বৈধ।
            [সূরাহ নিসা: আয়াত ২৯]
          </p>
          <p>
            তোমরা অন্যায়ভাবে একে অপরের সম্পদ ভোগ করো না। এবং জনগণের সম্পদের
            কিয়দংশ জেনে-শুনে পাপ পন্থায় আত্নসাৎ করার উদ্দেশে শাসন কতৃপক্ষের
            হাতেও তুলে দিও না। [সূরাহ বাক্কারা: আয়াত ১৮৮]
          </p>
          <p>
            অর্থাৎ, ধনসম্পদ যেন শুধুমাত্র বিত্তশালীদের মাঝেই ঘুরপাক না খায়, সেই
            লক্ষ্যেই অর্থনীতির কার্যকর ভূমিকা রাখা জরুরী। তা কেবল যাতে তত্ত্বের
            কিতাবেই আটকে না থাকে, সেই নির্দেশ দিয়েছেন আল্লাহ্‌।
          </p>
          <p>
            এছাড়া আল্লাহ পার্থিব সম্পদকেও তাঁর রহমত হিসেবে উল্লেখ করে তা বন্টনের
            সুনির্দিষ্ট নির্দেশ দিয়েছেন। আল্লাহ্‌ বলেন,   তারা কি আপনার
            পালনকর্তার রহমত বন্টন করে? আমি তাদের মধ্যে তাদের জীবিকা বন্টন করেছি
            পার্থিব জীবনে এবং একের মর্যাদাকে অপরের উপর উন্নীত করেছি, যাতে একে
            অপরকে সেবক রূপে গ্রহণ করে। তারা যা সঞ্চয় করে, আপনার পালনকর্তার রহমত
            তদপেক্ষা উত্তম। [সূরাহ আয যুখরুফ: আয়াত ৩২]
          </p>
          <p>
            সম্পদের সুষম বন্টনে প্রয়োজন সুষম ব্যয়। অতিরিক্ত ব্যয় করা যেমন
            রাষ্ট্রের অর্থনীতির জন্য হুমকি, তেমনি প্রয়োজনের কম ব্যয় করাও
            অর্থনীতির চাকার গতি রোধ করে দেয়। আল্লাহ্‌ বলেন,     তুমি একেবারে
            ব্যয়-কুষ্ঠ হয়োনা এবং একেবারে মুক্ত হস্তও হয়ো না। তাহলে তুমি
            তিরস্কৃতি, নিঃস্ব হয়ে বসে থাকবে। [সূরাহ আল ইসরা: আয়াত ২৯]
          </p>
          <p>
            ইসলামি অর্থনীতি ব্যবস্থা একটি কার্যকর ও বাস্তবমুখী ব্যবস্থা হিসেবে
            খিলাফত রাষ্ট্রে প্রচলিত ছিল যুগের পর যুগ। রাসূল [ﷺ] মদীনায় একাধিক
            শাখা বিশিষ্ট রাজস্ব ব্যবস্থা চালু করেন। পরবর্তীতে খুলাফা রাশেদীনের
            সময় তা অটুট রাখা হয়। প্রায় ৬টি বিভাগ নিয়ে রাজস্ব বিভাগ চালু করা হয়।
            সে অনুসারে উমাইয়্যা খিলাফতে এর কাঠামো বৃদ্ধি পেয়ে প্রায় ৯ টি শাখায়
            পরিণত হয়। অর্থনৈতিক সমৃদ্ধির চূড়ান্ত রূপ দেখে আব্বাসীয় খিলাফত। সে
            সময়কার মজবুত অর্থনৈতিক পরিস্থিতি সম্পর্কে ইতিহাসবিদ পি কে হিট্টি
            বলেন,  
          </p>
          <p>
            “হারুন অর রশিদের খিলাফতে বাগদাদের গৌরবোজ্জ্বল ইতিহাসকে সমুজ্জ্বল
            করবার জন্যে ইতিহাস ও রূপকথার সমন্বয় ঘটে।”
          </p>
          <p>
            ঠিক রূপকথাই বাস্তব হয়ে ধরা দিয়েছিল ইসলামি শাসন ব্যবস্থায়, যেখানে
            অর্থনীতিকে সত্যিকার অর্থেই সম্পদের সুষম বন্টনের পন্থা হিসেবে দেখা
            হয়েছিল। যেখানে বর্তমান অর্থনৈতিক ব্যবস্থা সমাজের প্রতিটি পরতে পরতে
            শোষন ব্যতীত কিছু দিতে সক্ষম হয় নি।
          </p>
          <p>
            সত্যিকার অর্থেই যারা অর্থনৈতিক মুক্তির তালাশ করেন, তাদের কাছে ইসলামি
            অর্থনীতি একটি অমূল্য সম্ভাবনা হিসেবে ধরা দিচ্ছে ধীরে ধীরে। বেশ
            কিছুদিন আগে ইসলামি অর্থনীতির বিষয়ে ইউনিভার্সিটি অফ মারবার্গ এর
            প্রফেসর Dr. Volker Nienhaus তাঁর “Fundamentals of an Islamic
            economic system compared to the social market economy” শীর্ষক
            আর্টিকেলে উল্লেখ করেন,   “Islamic economic systems are indeed
            compatible with the concept of the social market economy, and
            Islamic economics can act as an advocate of such a concept
            transfer.”    
          </p>
          <p>
            দ্যা গার্ডিয়ান পত্রিকার ফাইনান্স হাব সেকশানে বিগত ২০১৩ সনের
            অক্টোবারে প্রকাশিত একটি আর্টিকেলে লেখা হয়,      “Sharia law, which
            derives from the Qur'an and the religious teaching of Islam, can
            also be applied to the finance sector. Importantly, Islamic finance
            can be seen as part of a wider movement towards the promotion of
            sustainability as a key element of economic life.   Islamic finance
            is a legitimate expression of an economic philosophy of the use of
            money. This shouldn't be stigmatized or criminalized – especially in
            light of the excesses and abuses that preceded the recent global
            financial crisis.”
          </p>
          <p>
            ইসলামি অর্থনৈতিক ব্যবস্থা সম্পর্কে  ইকোনমিস্ট ও ফাইন্যান্স
            এনালিস্টদের মন্তব্য সংগ্রহ করে লেখা বাড়ানোর উদ্দেশ্য নেই। তবে উপরের
            দুটি মন্তব্য যোগ করার কারণ হল, ইসলামি অর্থনীতি কোন ধর্মীয় আবেগের
            বিষয় নয়, বরং এটা শারি’আর দাবি এবং একটি দেশের অর্থনীতিকে সচল রাখার
            একটি কার্যকরী উপায় যার সাক্ষ্য খোদ বর্তমান সময়ের বিশেষজ্ঞরাও দিচ্ছেন
            এবং রিসেশান বা অর্থনৈতিক মন্দাক্রান্ত এই বৈশ্বিক অর্থনীতির একটি
            সমাধান হিসেবেই ইসলামি অর্থনীতিকে দেখা হচ্ছে। তাই এটা বলার অপেক্ষা
            রাখে না, ইসলাম যথেষ্ট মজবুত খুঁটির ওপর দাঁড়িয়ে একটি জীবন ব্যবস্থা
            রূপে পৃথিবীর বুকে আত্মপ্রকাশ করেছে। একটি জীবন ব্যবস্থার অন্যতম
            ভিত্তি - অর্থনৈতিক দর্শন, যা ইসলাম অত্যন্ত কার্যকর উপায়ে দাঁড়
            করিয়েছে।
          </p>
          <p>
            [এ পর্বে ইসলামি অর্থনীতির মূল ভিত্তি নিয়ে আলোচনা করা হল। পরবর্তী
            পর্বে ইসলামি অর্থনীতিতে বর্তমান ক্যাপিটালিস্টিক অর্থনীতির অন্যতম
            প্রধান পরিচালক - সুদের অবস্থান নিয়ে আলোচনা করা হবে ইনশাআল্লাহ।]  
          </p>
          <p>এবং আল্লাহ সর্বজ্ঞানী</p>
        </div>
        <hr />
        <div>
          <h4 className="fw-bold">পর্ব - ২</h4>
          <p>
            আমরা গত পর্বে জেনেছি ইসলামি অর্থনীতির মূল ভিত্তি হল সম্পদের সুষম
            বন্টন। পুঁজিবাদী অর্থ ব্যবস্থার অর্থনীতির তত্ত্ব যদিও সেই কথাই বলে,
            তবে বাস্তবতায় দেখা যায়, সম্পদ কেবল বিত্তশালীদের হাতেই ঘুরপাক খায়।
            অথচ সম্পদ যাতে শুধুমাত্র বিত্তশালীদের হাতে পুঞ্জিভূত না হয়, সেটার
            দিকেই মূল লক্ষ্য রাখে ইসলামি অর্থনীতি। আল্লাহ্‌ তা’লা বলেন,    
            "আল্লাহ জনপদবাসীদের কাছ থেকে তাঁর রসূলকে যা দিয়েছেন, তা আল্লাহর,
            রসূলের, তাঁর আত্নীয়-স্বজনের, ইয়াতীমদের, অভাবগ্রস্তদের এবং মুসাফিরদের
            জন্যে, যাতে ধনৈশ্বর্য্য কেবল তোমাদের বিত্তশালীদের মধ্যেই পুঞ্জীভূত
            না হয়।" [সূরাহ হাশর: আয়াত ৭]
          </p>

          <p>
            আর এই সম্পদের সুষম বন্টনের ক্ষেত্রে প্রধান যে বাধার মুখোমুখি হতে হয়,
            তা হল সুদ। সুদ এমনই একটি মারাত্মক জুলম, যা বিত্তশালীদের পকেট ভারী
            করে সাধারণ জনতাকে পথে বসিয়ে দেয়। সুদের বাস্তবিক অপকারিতা নিয়ে
            ইনশাআল্লাহ্‌ বিস্তারিত আসবে পরবর্তী পর্বগুলোতে।
          </p>
          <p>
            ইসলামি অর্থনীতির একটি মৌলিক বৈশিষ্ট্য হল, এটি সুদ অস্বীকার করে।
            সুদের অস্তিত্ব বিলীন করতে চায় এবং সুদকে হারাম ঘোষণা করে। অথচ আমাদের
            প্রচলিত অর্থনৈতিক ব্যবস্থা সম্পূর্ণরূপে সুদের ওপর দাঁড়িয়ে আছে। তাই
            ইসলামে সুদের ব্যপারে কী বলা হয়েছে, কীভাবে সুদকে সংজ্ঞায়িত করা হয়েছে,
            কোন পর্যায়ে একে হারাম করা হয়েছে, আমাদের বর্তমান সুদী ব্যবস্থা এর
            সাথে কতটা যুক্ত, সে সংক্রান্ত জ্ঞান আহরণ করা প্রত্যকে ঈমানদার
            মুসলিমের উপর অবশ্য পালনীয় কর্তব্য। সুদের ব্যাপারে ইসলামের হুকুম যদি
            দেখা হয়, তাহলে কুরআন ও সুন্নাহ থেকে স্পষ্ট আদেশ লক্ষ্যণীয়।
          </p>
          <p className="fw-bold">কুরআন মাজিদ ও সুদ</p>
          <p>
            সুদের ব্যপারে আল্লাহ্‌ সুবহানাহুওয়া তা’লা কুরআনে একাধিক আয়াত নাযিল
            করেছেন। এইসব আয়াতে দেখার বিষয় হল, আল্লাহ্‌ আযাযা ওয়া জাল সুদকে
            বিন্দুমাত্র ছাড় দেন নি এবং কঠোর ভাষায় হারাম ঘোষনা করে দিয়েছেন। কোন
            অবস্থাতেই আমরা সুদকে হালাল করার সুযোগ পাচ্ছি না।
          </p>
          <p>
            “মানুষের ধন-সম্পদে তোমাদের ধন-সম্পদ বৃদ্ধি পাবে, এই আশায় তোমরা
            সুদে যা কিছু দাও, আল্লাহর কাছে তা বৃদ্ধি পায় না। পক্ষান্তরে, আল্লাহর
            সন্তুষ্টি লাভের আশায় পবিত্র অন্তরে যারা দিয়ে থাকে, অতএব, তারাই
            দ্বিগুণ লাভ করে।“ [সূরাহ আর রূম: আয়াত ৩৯]
          </p>
          <p>
            “বস্তুতঃ ইহুদীদের জন্য আমি হারাম করে দিয়েছি বহু পূত-পবিত্র বস্তু যা
            তাদের জন্য হালাল ছিল-তাদের পাপের কারণে এবং আল্লাহর পথে অধিক পরিমাণে
            বাধা দানের দরুন। আর এ কারণে যে, তারা সুদ গ্রহণ করত, অথচ এ ব্যাপারে
            নিষেধাজ্ঞা আরোপ করা হয়েছিল এবং এ কারণে যে, তারা অপরের সম্পদ ভোগ
            করতো অন্যায় ভাবে। বস্তুত; আমি কাফেরদের জন্য তৈরী করে রেখেছি
            বেদনাদায়ক আযাব।” [সূরাহ আন নিসা: আয়াত ১৬০ -১৬১]
          </p>
          <p>
            “ঈমানদারগণ! তোমরা সুদের উপর সুদ খেয়ো না। আর আল্লাহকে ভয় করতে থাক,
            যাতে তোমরা কল্যাণ অর্জন করতে পারো।” [সূরাহ আলি ইমরাআন: আয়াত ১৩০]  
          </p>
          <p>
            “যারা সুদ খায়, তারা কিয়ামতে দন্ডায়মান হবে, যেভাবে দন্ডায়মান হয় ঐ
            ব্যক্তি, যাকে শয়তান আসর করে মোহাবিষ্ট করে দেয়। তাদের এ অবস্থার কারণ
            এই যে, তারা বলেছেঃ ক্রয়-বিক্রয় ও তো সুদ নেয়ারই মত! অথচ আল্লা’হ
            তা’আলা ক্রয়-বিক্রয় বৈধ করেছেন এবং সুদ হারাম করেছেন। অতঃপর যার কাছে
            তার পালনকর্তার পক্ষ থেকে উপদেশ এসেছে এবং সে বিরত হয়েছে, পূর্বে যা
            হয়ে গেছে, তা তার। তার ব্যাপার আল্লাহর উপর নির্ভরশীল। আর যারা পুনরায়
            সুদ নেয়, তারাই দোযখে যাবে। তারা সেখানে চিরকাল অবস্থান করবে।” [সূরাহ
            আল বাক্কারা: আয়াত ২৭৫]  
          </p>
          <p>
            “আল্লাহ তা’আলা সুদকে নিশ্চিহ্ন করেন এবং দান খয়রাতকে বর্ধিত করেন।
            আল্লাহ পছন্দ করেন না কোন অবিশ্বাসী পাপীকে।” [সূরাহ আল বাক্কারা:
            আয়াত ২৭৬]
          </p>
          <p>
            “হে ঈমানদারগণ, তোমরা আল্লাহকে ভয় কর এবং সুদের যে সমস্ত বকেয়া আছে,
            তা পরিত্যাগ কর, যদি তোমরা ঈমানদার হয়ে থাক।” [সূরাহ আল বাক্কারা:
            আয়াত ২৭৮]
          </p>
          <p>
            “অতঃপর যদি তোমরা (সুদ) পরিত্যাগ না কর, তবে আল্লাহ ও তাঁর রসূলের
            সাথে যুদ্ধ করতে প্রস্তুত হয়ে যাও। কিন্তু যদি তোমরা তওবা কর, তবে
            তোমরা নিজের মূলধন পেয়ে যাবে। তোমরা কারও প্রতি অত্যাচার করো না এবং
            কেউ তোমাদের প্রতি অত্যাচার করবে না।” [সূরাহ আল বাক্কারা: আয়াত ২৭৯]
          </p>
          <p>
            সুদের সাথে এইভাবেই সরাসরি সম্পর্কচ্ছেদ করেছেন আল্লাহ্‌। বর্তমান
            সুদভিত্তিক ঋণ ব্যবস্থায় ঋণ গ্রহীতার ওপর যদি ঋণ পরিশোধ কোন কারণে
            কষ্টকর হয়ে যায়, তাহলে ফাইনান্স হাউসগুলো তাকে ছাড় দেয় না। মর্টগেজ বা
            বন্ধক বিক্রি করে তারা সুদ ও আসল তুলে নিয়ে আসে। অথচ আল্লাহ্‌ বলছেন,  
              “যদি কেউ যদি অভাবগ্রস্থ হয়, তবে তাকে সচ্ছলতা আসা পর্যন্ত সময় দেয়া
            উচিত। আর যদি ক্ষমা করে দাও, তবে তা খুবই উত্তম যদি তোমরা উপলব্ধি
            কর।” [সূরাহ আল বাক্কারা: আয়াত ২৮০]  
          </p>
          <p>
            লক্ষ্য করুন, ঋণ পরিশোধের সময় অতিবাহিত হয়ে গেলে ঋণ দাতার অবশ্যই দাবি
            থাকে সেই ঋণের ওপর এবং বর্তমান ব্যবস্থায় ঋণ গ্রহীতার লেপ কম্বল বিক্রি
            করে হলেও সুদ আদায় করা হয়। কিন্তু আল্লাহ্‌ বলছেন তাকে সময় দিতে! কোন
            অবস্থাতেই যাতে তাকে অতিরিক্ত উসুল বা সুদের খপ্পরে পড়তে না হয়। যদিও
            ইসলামি ঋণ ব্যবস্থা সম্পূর্ণ সুদমুক্ত।
          </p>
          <p>
            তাই কুরআন মাজিদের আলোকে স্পষ্ট দেখা যাচ্ছে সুদের ব্যপারে এক বিন্দু
            ছাড় দেয়া সম্ভব নয়।
          </p>

          <p className="fw-bold">হাদীস ও সুদ</p>
          <p>
            কুরআন এর আয়াতের আলোকে রাসূলুল্লাহ [ﷺ] একাধিকবার সুদের ব্যাপারে এই
            উম্মাহকে হুঁশিয়ার করে দিয়েছেন। দিয়েছেন অভিশাপ ও ভয়ঙ্কর শাস্তির খবর।
             
          </p>
          <p>
            রাসূল সেই নারীকে অভিশাপ দিয়েছেন যে ট্যাটু আঁকা চর্চা করে এবং সেই
            নারী যে ট্যাটু আঁকায়, এবং সেই ব্যক্তি যে সুদ ভক্ষণ করে এবং যে সুদ
            দেয়।  [সহীহ আল বুখারী : ৫৩৪৭]  
          </p>
          <p>
            আল্লাহ্‌র রাসূল অভিশাপ দিয়েছেন সুদ গ্রহীতা, সুদ দাতা, সুদের
            হিসাবরক্ষের এবং এ ব্যাপারে সাক্ষ্যদানকারীর ওপর আর বলেছেন “ওরা সকলেই
            সমান” [সহীহ মুসলিম ১৫৯৭]
          </p>
          <p>
            সুদের ৭৩ টি অপকারিত রয়েছে। তন্মধ্যে সর্বনিম্ন হল আপন মায়ের সাথে
            জিনা/ব্যভিচার করা।  [সুনান ইবন মাজাহ : ২২৭৪ হাসান হাদীস (দারুস
            সালাম)। সোর্স: Sunnah.com] 
          </p>

          <p>
            রাসূল বলেন, “তোমরা সাতটি ধ্বংসাত্মক বস্তু থেকে বেচে থাকো।” সাহাবীগণ
            বললেন, “হে আল্লাহ্‌র রাসূল! সেগুলো কী?” তিনি উত্তরে বললেন,
            “আল্লাহ্‌র সাথে শিরক করা,জাদু করা,অন্যায়ভাবে কাউকে হত্যা করা যা
            আল্লাহ্‌ হারাম করেছেন, সুদ খাওয়া, এতিমের সম্পদ গ্রাস করা, যুদ্ধের
            সময় পলায়ন করা, সতী-সাধ্বী নিরীহ ঈমানদার নারীর প্রতি অপবাদ আরোপ
            করা।”  [সহীহ আল বুখারী : ৬৮৫৭]  
          </p>
          <p>
            আল্লাহ্‌র রাসূল যে ব্যক্তি সুদ নেয় ও যে ব্যক্তি সুদ দেয়, তাদের
            অভিশাপ দিয়েছেন [সহীহ মুসলিম : ১৮:১০৫]
          </p>
          <p>
            আল্লাহ্‌র রাসূল বলেন, এমন একটি সময় আসবে, যখন সুদ খাবেনা এমন কেউ
            থাকবেনা, আর কেউ যদি সুদ নাও খায়, তবু এর ধূলোবালি থেকে রক্ষা পাবে না।
            [ইবন মাজাহ, আবু দাউদ, আল জামি’আল আসগার সুয়ুতী, পৃষ্ঠা ৭৫৩১ : সহীহ
            (সোর্স: hdith.com)]
          </p>
          <p>
              রাসূল [ﷺ] এর হাদীস থেকেও দেখা যাচ্ছে সুদের হারাম হবার কথা।
            উল্লিখিত সহীহ হাদীসগুলো ছাড়াও আরো অসংখ্য হাসান পর্যায়ের ও কিছু
            দুর্বল বর্ণনার হাদীসও রয়েছে, যাতে বর্ণিত হয়েছে সুদের ভয়ানক পরিণতির
            কথা। কোনটায় আল্লাহ্‌র রাসূল [ﷺ]  বলেছেন তিনি মিরাজের সময় সুদখোরদের
            পেটে সাপ কিলবিল করতে দেখেছেন, কোনটায় তিনি বলেছেন একজন মুসলিমের এক
            দিরহাম সুদ গ্রহণ করা ৩৩ বা ৩৬ বার ব্যভিচার/জিনা করার চাইতেও জঘন্য।  
          </p>
          <p className="fw-bold">ইসলাম ও সুদ</p>
          <p>
            কুরআন ও হাদীসের আলোকে আলোচনার মাধ্যমে এটা স্পষ্ট হয়ে উঠেছে, কোন
            মুসলিমের পক্ষে সুদকে হালাল ভাবার কোন সম্ভাবনা ও সুযোগ নেই। ইসলামে
            অত্যন্ত জঘন্য একটি স্থান দখল করে আছে সুদ। ভেবে দেখুন, কেউ তার
            জন্মদাত্রী মায়ের সাথে ব্যভিচার করতে পারে কি? সম্ভব? অথচ সুদ খাওয়ার
            ৭৩ টি গুনাহ এর সর্বনিম্ন গুনাহ হল সেই ব্যক্তির গুনাহ এর সমান, যে
            তাঁর জন্মদাত্রী মায়ের সাথে জিনা করে।  
          </p>
          <p>
            ভেবে দেখুন তো, কেউ একই দিনে ৩৬ বার জিনা করে ফেলেছে! কতটা ভয়ংকর হতে
            পারে তার গুনাহ? তার শাস্তি? অথচ এক দিরহাম সুদ গ্রহণকে ৩৬ বার জিনা
            করার চাইতেও জঘন্যতর আখ্যা দেয়া হয়েছে।  
          </p>
          <p>
            তাহলে আমাদের কি এতটুকু সতর্ক হবার প্রয়োজন নেই? আমাদের কি এতটুকু ভীত
            হবার দরকার নেই? আমাদের এতটুকু চিন্তা করার অবকাশ নেই যে আমরা সুদকে
            কীভাবে নিচ্ছি?
          </p>
          <p>আসুন আমরা নিজেদের চারটি প্রশ্ন করি।</p>
          <p>
              - আমরা কি সুদ নিচ্ছি?
            <br />
            - আমরা কি সুদ দিচ্ছি?
            <br />
            - আমরা কি সুদী কারবার লিখে রাখছি?
            <br />- আমরা কি সুদী কারবারে সাক্ষী থাকছি/ভূমিকা রাখছি?
          </p>
          <p>
            এই চার শ্রেণীর মানুষকেই আল্লাহ্‌র রাসূল [ﷺ] অভিশাপ দিয়ে বলেছেন এরা
            সবাই একই গুনাহগার!  
          </p>
          <p>
            আমরা অনেকেই হয়তো নামায পড়ি, রোজা রাখি, কুরআন পাঠ করি, চেষ্টা করি
            হারাম কিছু না করতে, অথচ ঠিকই এমন ভাবে সুদী কারবারে জড়িয়ে পড়ছি, যেন
            এটা কোন বিষয়ই নয়!
          </p>
          <p>
            অথচ যে ব্যক্তি হারাম উপার্জন করে তা দিয়ে উদরপূর্তি করে, তার দু’আ
            কিংবা ইবাদাত কবুলই হয় না! রাসূলাল্লাহ [ﷺ] বলেন,
          </p>
          <p>
            ‘‘নিশ্চয়ই আল্লাহ তাআলা পবিত্র। তিনি শুধু পবিত্র বস্ত্তই গ্রহণ করেন।
            তিনি মুমিনদের সেই আদেশই দিয়েছেন, যে আদেশ তিনি দিয়েছিলেন রাসূলগণের।’’
            আল্লাহ তা’আলা বলেন : ‘‘হে ইমানদারগণ! তোমরা পবিত্র বস্ত্ত-সামগ্রী
            আহার কর, যেগুলো আমি তোমাদেরকে রুযী হিসেবে দান করেছি।’’ অতঃপর রাসূল
            সা. এমন এক ব্যক্তির কথা উল্লেখ করলেন, যে দীর্ঘ সফরে থাকা অবস্থায়
            এলোমেলো চুল ও ধূলি-ধুসরিত ক্লান্ত-শ্রান্ত বদনে আকাশের দিকে আল্লাহর
            দরবারে হাত তুলে প্রার্থনা করে ডাকছেঃ হে আমার প্রভূ! হে আমার প্রভূ!
             সে যা খায় তা হারাম, যা পান করে তা হারাম, যা পরিধান করে তা হারাম এবং
            হারামের দ্বারা সে পুষ্টি অর্জন করে। তার প্রার্থনা কিভাবে কবুল হবে?’’
            [সহীহ মুসলিম : ১০১৫]
          </p>
          <p>
            আর যে দেহ হারাম খাদ্য দ্বারা গড়ে উঠে তার জন্য দোযখের আগুনই উত্তম।
            [জামি’ আত তিরমিদি : ৬১৪ হাসান হাদীস: দারুস সালাম (সোর্স: Sunnah.com)
            ]
          </p>
          <p>
            স্কলারদের মতে যে শরীর হারাম উপার্জনে পুষ্টি লাভ করে, সে শরীর
            আল্লাহ্‌র নিকট অপবিত্র। ফলে তাঁর ইবাদত কী উপায়ে কবুল করে পারে?
          </p>
          <p>
            স্পষ্টতই সুদের ব্যাপারে আল্লাহ্‌ ও তাঁর রাসূল [ﷺ] অত্যন্ত কঠোর নীতি
            অবলম্বন করেছেন। যে ব্যক্তি সুদের সাথে সম্পর্ক রাখে, আল্লাহ্‌ তা’লা
            বলেছেন সে ব্যক্তি আল্লাহ্‌ ও রাসূলুল্লাহর [ﷺ] সাথে সরাসরি যুদ্ধ
            ঘোষণা করে। একজন সুস্থ মস্তিষ্কের মুসলিম কখনই আল্লাহ্‌ এবং তাঁর
            রাসূলের [ﷺ] সাথে যুদ্ধ ঘোষণা করার কথা সুদূর কল্পনাতেও আনবেন না।
            কিন্তু সুদী কারবারে লিপ্ত হয়ে অনেকেই প্রতিনিয়ত আল্লাহ্‌ ও তাঁর
            রাসূলের [ﷺ] বিরুদ্ধে যুদ্ধ ঘোষণা করছেন।
          </p>
          <p>
            একজন মুসলিমের কাছে আল্লাহ্‌র আদেশ ও নিষেধের বাইরে কোন কিছুই বলার
            থাকে না, কোন কিছুই করার থাকে না। একজন মুসলিম সেটাই মেনে নেবে যা
            আল্লাহ্‌ আদেশ করেছেন। আর সেখান থেকেই দূরে থাকবে যা আল্লাহ্‌ নিষেধ
            করেছেন। আল্লাহ্‌ বলেন,  
            <br />
            "আল্লাহ ও তাঁর রসূল কোন কাজের আদেশ করলে কোন ঈমানদার পুরুষ ও
            ঈমানদার নারীর সে বিষয়ে ভিন্ন ক্ষমতা নেই যে, আল্লাহ ও তাঁর রসূলের
            আদেশ অমান্য করে সে প্রকাশ্য পথভ্রষ্ট তায় পতিত হয়।" [সূরাহ আল আহযাব :
            আয়াত ৩৬]
          </p>
          <p>
            অর্থাৎ আল্লাহ্‌ এবং তাঁর রাসূল [ﷺ] যখন কোন বিষয়ে স্পষ্ট নিষেধাজ্ঞা
            আরোপ করবেন, তখন মু’মিন পুরুষ ও নারী কারোরই কোন এখতিয়ার নেই সেখানে
            দ্বিমত পোষণ করার।
          </p>
          <p>
            আল্লাহ্‌ সুবহানাহু ওয়া তা’লা সুদকে হারাম করেছেন তো আমরাও মেনে নেব।
            কোন প্রশ্ন ছাড়া। কোন তর্ক ছাড়া। আল্লাহ্‌ মুসলিমদের পরিচয় কুরআনে
            এভাবেই দিয়েছেন, <br />
            তারা বলে, আমরা শুনলাম এবং মানলাম। [সূরাহ নূর : আয়াত ৫১]
          </p>

          <p>
            [এ পর্বে আমাদের আলোচনা ছিল সুদের হারাম হবার হুকুমের ওপর। পরবর্তী
            পর্বে সুদ ও রিবা এর সংজ্ঞাও শ্রেণীবিন্যাস সম্পর্কে আলোচনা করা
            হবে ইনশাআল্লাহ্‌।]  
          </p>
        </div>

        <hr />

        <div>
          <h4 className="fw-bold">পর্ব - ৩</h4>
          <p>
            আমরা গত পর্বে ইসলামি জীবন ব্যবস্থায় ‘রিবা’ এর অবস্থান নিয়ে আলোচনা
            করেছি। কুর'আন ও সুন্নাহ-এর অকাট্য দলীলের মাধ্যমে আমরা পরিষ্কার দেখতে
            পাই, ইসলামে রিবাকে হারাম ঘোষণা করা হয়েছে। এই পর্বে আমরা আলোচনা করবো
            রিবার পরিচয় নিয়ে।
          </p>
          <p>
            আলোচনার শুরুতেই যে বিষয়টা জানা প্রয়োজন, তা হল ইসলামে রিবা শব্দটি
            একটি ব্যপক অর্থবোধক শব্দ। আমরা অনেকেই রিবা বলতে শুধু সুদ বুঝে থাকি।
            অর্থাৎ ইংরেজি Interest বা usury বুঝে থাকি। কিন্তু বাস্তব কথা হল রিবা
            শুধু সুদ শব্দে সীমাবদ্ধ নয়। এটি আরো অধিক কিছুই বোঝায়। বিভিন্ন
            গ্রন্থে ফকীহগণ রিবা শব্দের ৫ রকম ব্যবহার উল্লেখ করেছেন।
          </p>
          <ul>
            <li>সুদ। এ নিয়ে আলোচনা আসছে।</li>
            <li>পণ্য বিনিময়ে বেশি/কম করা। এ নিয়েও আলোচনা হবে ইনশাআল্লাহ।</li>
            <li>
              ক্রয়-বিক্রয়ে বিভিন্ন অবৈধ পন্থা অবলম্বনের খেত্রেও রিবা শব্দটি
              ব্যবহার হয়ে থাকে। যেমন, সূরা নিসার ১৬১ নাম্বার আয়াত “আর এ কারণে
              যে, তারা রিবা গ্রহণ করত, অথচ এ ব্যাপারে নিষেধাজ্ঞা আরোপ করা
              হয়েছিল এবং এ কারণে যে, তারা অপরের সম্পদ ভোগ করতো অন্যায়
              ভাবে।” এর তাফসীরে কতিপয় মুফাসসিরগণ ‘রিবা’র তাফসীর করেছেন
              ক্রয়-বিক্রয়ে অবৈধ পন্থা হিসেবে।
            </li>
            <li>
              প্রতিদান পাওয়ার আশায় কাউকে কিছু দেয়ার ক্ষেত্রেও রিবা শব্দটি
              ব্যবহার হতে পারে। যেমন সূরা রূমের ৩৯ নাম্বার আয়াত “মানুষের
              ধন-সম্পদে তোমাদের ধন-সম্পদ বৃদ্ধি পাবে, এই আশায় তোমরা ‘রিবা’তে
              যা কিছু দাও, আল্লাহর কাছে তা বৃদ্ধি পায় না” এর তাফসীরে অনেক
              মুফাসসির রিবাকে এই অর্থেই নিয়েছেন।
            </li>
            <li>
              যে কোন হারাম কাজের ক্ষেত্রেও রিবা ব্যবহৃত হতে পারে। যেমন ‘কানযুল
              উম্মাল’ এ একটি হাদীস পাওয়া যায়, যেখানে বলা হচ্ছে, “সবচাইতে নিকৃষ্ট
              রিবা হল এক ভাই আরেক ভাই এর মান-মর্যাদা ক্ষুন্ন করা।”
            </li>
          </ul>
          <p>
            তবে শেষ তিন প্রকারে রিবা শব্দের ব্যবহার নেই বললেই চলে কিংবা একেবারেই
            অপ্রচলিত।
          </p>

          <p>
            আরবী ভাষায় ربا (রিবা) এর শাব্দিক অর্থ হল আধিক্য, বৃদ্ধি, অতিক্রম
            করা, আকার ও সংখ্যায় কোন কিছু বৃদ্ধি পাওয়া। আহকামুল কুর’আন গ্রন্থে
            ইবনুল আরাবী বলেন, “রিবার আভিধানিক অর্থ হল বৃদ্ধি।”  
          </p>

          <p>
            তাই ব্যবসা ও ক্রয়-বিক্রয়ে অতিরিক্ত নেবার ক্ষেত্রে কিছু কিছু অতিরিক্ত
            নেওয়াকে ইসলামে রিবা বলা হয় এবং তা হারাম করা হয়েছে। তবে সব প্রকার
            অতিরিক্ত গ্রহণই রিবা নয়। বরং নির্দিষ্ট কিছু প্রকার অতিরিক্ত গ্রহণই
            হল রিবা।  
          </p>

          <p>
            ইসলামে ব্যবসা ও বিনিময় সংক্রান্ত মুয়ামালাতে রিবা মূলত দুই রকম।
            <br />
            ১। রিবা আন–নাসি’আতু [ربا النسيئة]
            <br />
            ২। রিবা আল-ফাদল [ربا الفضل]
          </p>

          <p className="fw-bold">রিবা আন–নাসি’আ [ربا النسيئة]</p>
          <p>
            রিবা আন-নাসি’আকে অনেক ফকীহ রিবা আল-কুর’আন [ربا القران] হিসেবেও
            নামকরণ করেছেন কেননা এই ধরণের রিবার কথা সরাসরি কুর’আনে এসেছে।   এই
            প্রকারের সুদের সনাক্তকারী বৈশিষ্ট্য, সালাফ আস সালেহীনের বিভিন্ন
            বক্তব্যের মাধ্যমে সহজবোধ্য হয়ে যায়।
          </p>

          <p>
            আলী (রা) বলেছেন,   “যে ঋণ লাভ টেনে আনে, তাই সুদ”। [আল ‘আযীযী (হাসান
            লি ঘাইরিহী), আসসিরাজুল মুনীর, মিসর]
          </p>
          <p>
            ফুযালা ইবন উবাইদ (রা) বলেছেন, “যে ঋণ লাভ টেনে আনে, তা এক প্রকারের
            সুদ” [বাইহাকী]
          </p>
          <p>
            ইমাম আবূ বাকর জাসসাস রাযী রিবা আন-নাসি’আকে রিবা আল-কুর’আন নামকরণ করে
            বলেন,   “মেয়াদের ভিত্তিতে অতিরিক্ত অর্থ প্রদানের শর্তে যে ঋণ দেয়া
            হয়, তাকে রিবা আল-কুর’আন বলে” [আহকামুল কুর’আন, মিসর]  
          </p>
          <p>
            ইবন জারীর বলেন,  “জাহিলী যুগে প্রচলিত ও কুর’আনে নিষিদ্ধ রিবা হল
            কাউকে নির্দিষ্ট মেয়াদের জন্য ঋণ দিয়ে মূলধনের অতিরিক্ত নির্দিষ্ট
            পরিমাণ অর্থ গ্রহণ করা।” [তাফসীর ইবন জারীর]  
          </p>
          <p>
            আরবী ভাষার সুপ্রসিদ্ধ পণ্ডিত আবূ ইসহাক যাজ্জাজ রিবা আন নাসি’আকে
            সংজ্ঞায়িত করেছেন এইভাবে, “প্রদত্ত ঋণের পরিমাণের চেয়ে অতিরিক্ত উসূল
            করাই হল রিবা” [তাজুল ‘উরূস]
          </p>
          <p>
            উপরোক্ত সংজ্ঞাগুলোকে সহজ করে বলতে গেলে যা দাঁড়ায় তা হল, ঋণ দিয়ে সেই
            ঋণ ফেরত নেবার সময় চুক্তি মোতাবেক কেউ যদি ১ পয়সাও অতিরিক্ত গ্রহণ করে,
            তবে সেই ১ পয়সা হবে রিবা আন-নাসি’আ। অর্থাৎ ঋণের মূলধনের সাথে কেউ যদি
            অতিরিক্ত কোন অর্থ আদায় করে, তবে সেটাই রিবা।
          </p>
          <p>
            এখানে স্পষ্ট একটি বিষয় জেনে রাখা প্রয়োজন, সেটি হল ইসলামে ঋণের ধারণা
            খুবই সহজ। ইসলামের ঋণের কোন অতিরিক্ত ফায়দা আসতে পারে না। অর্থাৎ কেউ
            যদি কাউকে ১০০ টাকা ঋণ দেয়, নির্দিষ্ট সময় পরেও সেই টাকা ফেরত নিতে
            গেলে তাকে ১০০ টাকাই ফেরত নিতে হবে। ১০১ টাকাও নিতে পারবেনা। এই কারণে
            ইসলামে ঋণের প্রকারভেদ একটাই। আর সেটা হল কারদ আল-হাসানা। ইসলামে
            এই কারদ আল-হাসানা ব্যতীত কোন প্রকার ঋণের স্থান নেই। অর্থাৎ ঋণ দিয়ে
            অতিরিক্ত অর্থসহ তা ফেরত নেয়া ইসলামে কোন ক্রমেই জায়িয নয়, এবং কেউ যদি
            এইভাবে ঋণ ফেরত নিয়ে থাকে, তবে সেটা রিবা আন-নাসি’আ। যেমনটা আমরা
            সালাফদের সংজ্ঞা থেকে জানলাম। এখানে আরো বলে রাখা প্রয়োজন, আমরা আমাদের
            সমাজে সুদ বলতে যা বুঝি, তা মূলত রিবা আন-নাসি’আ। অর্থাৎ এক প্রকারের
            রিবা। রিবা মানেই শুধু সুদ নয়। বরং রিবা আন-নাসি’আ মানে সুদ।
          </p>
          <p>
            উল্লেখ্য, ঋণের অতিরিক্ত আদায় রিবা হবে তখনই, যখন তা চুক্তিতে উল্লেখ
            করা থাকবে। কিন্তু চুক্তির বাইরে কেউ যদি স্বেচ্ছায়, উপহার হিসেবে
            কাউকে অতিরিক্ত দান করে, সেক্ষেত্রে তা হিবা বা গিফট হিসেবেই গণ্য হবে।
            রিবা নয়।
          </p>
          <p>
            জাবির ইবন আব্দুল্লাহ (রা) বলেন, “আমি রাসূল সাল্লাল্লাহু আলাইহিস
            সালামের কাছে আসি যখন তিনি মাসজিদে ছিলেন আর আমাকে বললেন, ‘দুই রাকাত
            পড়ে নাও’। আমি তার পাওনাদার ছিলাম, তাই তিনি আমার পাওয়া ফেরত দিলেন আর
            কিছু অতিরিক্তও দিলেন।” [বুখারী ৪৪৩]
          </p>
          <p>
            এছাড়াও রাসূল সাল্লাল্লাহু আলাইহিস সালাম এক ব্যক্তির কাছে একটি বাচ্চা
            উট ধার নেন। ফেরত দেবার সময় সাহাবীরা ঐ বাচ্চা উটটি খুঁজে পেলেন না।
            পরিবর্তে পেলেন এমন একটি উট যা কিছুটা যৌবন প্রাপ্ত ও হৃষ্টপুষ্ট।
            রাসূল সাল্লাল্লাহু আলাইহিস সালাম বললেন, “(এটিকেই) দিয়ে দাও। তোমাদের
            মধ্যে সেই উত্তম যে ধার শোধে উত্তম।” [বুখারী ২৩০৫]
          </p>
          <p>
            হাদীসদ্বয় থেকে জানা যায়, এই অতিরিক্ত দান যদি চুক্তিতে উল্লেখ না
            থাকে, এবং তা দেনাদারের স্বেচ্ছায় ও খুশিতে হয়ে থাকে, তাহলে এই
            অতিরিক্ত প্রদান-গ্রহণ করা জায়িয। আর যদি চুক্তি করা থাকে, যে ফিরিয়ে
            দেবার সময় কিছু অতিরিক্ত দিতে হবে, তবে সেটা রিবা আন-নাসি’আ এবং তা
            হারাম।
          </p>
          <p>
            আরো বিস্তারিতভাবে বলা যায়, যদি কোন ব্যক্তি কাউকে ঋণ দেবার সময় এই বলে
            ঋণ দেয়, যে ফেরত দেবার সময় অতিরিক্ত কিছু দিতে হবে, তাহলে এই অতিরিক্ত
            হল রিবা আন নাসি’আ। আর যদি কোনরূপ কিছুই বলা না থাকে বা চুক্তি করা না
            থাকে, অর্থাৎ কারদ আল-হাসানা দেয়, কিন্তু ঋণ গ্রহীতা নিজ খুশিতেই ফেরত
            দেবার সময় উত্তম কিছু বা অতিরিক্ত কিছু দেয়, তাহলে এটি রিবা নয়।
          </p>
          <p>
            [এই পর্বে রিবা আন-নাসি’আ নিয়ে আলোচনা করা হল। পরবর্তী পর্বে রিবার
            দ্বিতীয় প্রকার – রিবা আল-ফাদল নিয়ে আলোচনা করা হবে ইনশাআল্লাহ্‌]
          </p>
          <p>এবং আল্লাহ সর্বজ্ঞানী</p>
        </div>
        <hr />
        <div>
          <h4 className="fw-bold">পর্ব - ৪</h4>
          <p>
            গত পর্বে আমরা আলোচনা করেছি রিবার প্রথম প্রকার - রিবা আন নাসিআ নিয়ে।
            এ পর্বে আমরা আলোচনা করবো রিবার দ্বিতীয় প্রকার - রিবা আল ফাদল নিয়ে। 
          </p>
          <p className="fw-bold">রিবা আল-ফাদল [ ربا الفضل] </p>
          <p>
            রিবার সবচাইতে ব্যপক প্রকার হল রিবা আল-ফাদল। রিবা আন-নাসি’আতু যেমন
            সহজবোধ্য আর পরিষ্কার, রিবা আল-ফাদল সে তুলনায় কিছুটা জটিল। এই
            প্রকারের রিবা যেহেতু হাদীসের মাধ্যমে প্রমাণিত, তাই একে অনেক ফকীহ
            রিবা আল হাদীসও বলেছেন।
          </p>
          <p>
            সহজ ভাষায় বলতে গেলে, বিশেষ কোন পণ্য, সমজাত পণ্যের সাথে বিনিময় করার
            সময় যা অতিরিক্ত নেয়া হয়।
          </p>
          <p>
            রিবা আল ফাদল মূলত যেই হাদীসের উপর ভিত্তি করে হারাম করা হয়েছে, তা
            নিম্নরূপ।
          </p>
          <p>
            “সোনার বিনিময়ে সোনা সমান সমান বিক্রয় কর।
            <br />
            রূপার বিনিময়ে রূপা সমান সমান বিক্রয় কর।
            <br />
            খেজুরের বিনিময়ে খেজুর সমান সমান বিক্রয় কর।
            <br />
            গমের বিনিময়ে গম সমান সমান বিক্রয় কর। <br />
            লবণের বিনিময়ে লবণ সমান সমান বিক্রয় কর।
            <br />
            যবের বিনিময়ে যব সমান সমান বিক্রয় কর।
            <br /> যে বেশি আদানপ্রদান করলো সে রিবা আদান প্রদান করলো। আর রূপার
            বিনিময়ে স্বর্ণ যেভাবে ইচ্ছা, সে ভাবেই
            <br /> বিক্রয় করতে পারো। তবে শর্ত হল হাতে হাতে হতে হবে এবং খেজুরের
            বিনিময়ে যব যেভাবে ইচ্ছা বিক্রয় <br />
            করতে পারো, শর্ত হল হাতে হাতে হতে হবে।” [তিরমীদির বর্ণনা]
          </p>

          <p>
            এই হাদীসে ছয়টি বিশেষ পণ্যের কথা এসেছে, যেসব পণ্যের বিনিময়ের সময় একই
            জাতের হয়ে থাকলে একদম সমান সমান হতে হবে। অর্থাৎ ১০ গ্রাম সোনার
            বিনিময়ে ১১ গ্রাম সোনা দেয়া যাবে না। ১০ গ্রামই দিতে হবে। তেমনি ১০
            গ্রাম রূপার বিনিময়ে ১১ গ্রাম রূপা দেয়া যাবে না। ১০ গ্রামই দিতে হবে।
            তেমনি খেজুর, গম, লবণ ও যবের স্বজাতি বিনিময়ের একই হুকুম। যেমন ১ কেজি
            খেজুরের বিনিময় ১ কেজি খেজুরের সাথেই হতে হবে। ১ কেজি ১ গ্রাম এর সাথেও
            হতে পারবে না।
          </p>
          <p>
            অন্যদিকে, যদি সোনার সাথে রূপার কিংবা খেজুরের সাথে লবণের বিনিময় হয়ে
            থাকে, অর্থাৎ একই জাতের মধ্যে বিনিময় না হয়ে ভিন্ন জাতের মধ্যে বিনিময়
            হয়ে থাকে, তবে কম বেশি করা যাবে, তবে অবশ্যই তা নগদে হতে হবে। অর্থাৎ
            হাতে হাতে হতে হবে। বাকিতে করা যাবে না।
          </p>

          <p>
            ইসলামি বেচা-কেনা পদ্ধতির একটি গুরুত্বপূর্ণ অংশ হল মাজলিস। এর চলতি
            ভাষায় সহজ অর্থ করা যায় সেশান। অর্থাৎ একটি বেচাকেনা যেই সেশানের মধ্যে
            সম্পন্ন হয়, তাকে বলা হয় মাজলিস। মাজলিসের ওপর বেচাবিক্রির অনেক হুকুমই
            নির্ভর করে। এখানে রিবা আল ফাদল এর ব্যাখ্যার ক্ষেত্রে মাজলিসের হুকুম
            হল, যদি উক্ত ৬ জাতের পণ্য পরস্পর বিনিময় করা হয়, অর্থাৎ, একই প্রকারের
            পণ্য পরস্পর বিনিময় করা হোক (যেমন, সোনার বিনিময়ে সোনা, খেজুরের
            বিনিময়ে খেজুর) আর ভিন্ন প্রকারের পণ্য বিনিময় করা হোক (যেমন, সোনার
            বিনিময়ে গম, রূপার বিনিময়ে খেজুর, সোনার বিনিময়ে রূপা) তাহলে তা একই
            মাজলিসে সম্পন্ন হতে হবে। অর্থাৎ বাকি রাখা যাবে না।  
          </p>
          <p className="fw-bold">আমওয়ালুর রিবায়াত</p>
          <p>
              ফিকহের পরিভাষায় হাদীসে উল্লিখিত ৬ ধরণের পণ্যকে, সোনা, রূপা, খেজুর,
            গম, লবণ, যব - আমওয়ালুর রিবায়াত বা সুদী পণ্য বা রিবাউই পণ্য বলা হয়।
            কেননা এইসব পণ্যের পরস্পর বিনিময়ের ফলেই সুদ তৈরি হবার সম্ভাবনা থাকে।
          </p>
          <p>
            তবে হাদীসে এটার উল্লেখ নেই, যে শুধুমাত্র এই ৬ পণ্যই কি তাহলে রিবাউই
            পণ্য বলে বিবেচ্য হবে?  
          </p>
          <p>
            এটি অনেক জটিল একটি প্রশ্ন, যার উত্তরে উলামা কিরাম মতপার্থক্য করেছেন।
            প্রাথমিক যুগের অনেক ফকীহ এর মত ছিল রিবাউই পণ্য শুধুমাত্র এই ৬টি
            পণ্যেই সীমাবদ্ধ। এর মাঝে আছেন কাতাদা (রহ) ও তাউস (রহ)। তবে অন্যান্য
            ফকীহগণ এর সাথে ভিন্নমত পোষণ করেছেন। পরবর্তী ফকীহদের কথা হল, আমাদের
            দেখা উচিৎ, এই ৬টি পণ্যের মাঝে আসলে মূল মিল কোন জায়গায়, অর্থাৎ এদের
            মূল বৈশিষ্ট্য কী। যদি কোন পণ্যের মাঝে ঐ মৌলিক বৈশিষ্ট্য পাওয়া যায়,
            তাহলে সেটিও রিবাউই পণ্য হিসেবে গণ্য হবে। এক্ষেত্রে সালাফ উস
            সালেহীনগণ কয়েকটি মত দিয়েছেন।
          </p>

          <ul>
            <li>
              ইমাম আবু হানিফা (রহ) বলেন, এই ৬ পণ্যের মূল বৈশিষ্ট্য হল পরিমাপ ও
              ওজন। অর্থাৎ এই ৬ টি পণ্যকে পাত্র দারা পরিমাপ করা যায় ও ওজন করে
              বিক্রি করা যায়। তাই অন্য যে কোন পণ্য যদি পাত্র দ্বারা পরিমাপ করা
              যায় আর ওজন করে বিক্রি করা যায়, তাহলে সেটাও রিবাউই পণ্য হবে।
            </li>
            <li>
              ইমাম মালিক (রহ) বলে, এই ৬ পণ্যের মূল বৈশিষ্ট্য হল খাদ্য জাতীয় হওয়া
              এবং গুদামজাত করা উপযুক্ত হওয়া। তাই যে কোন পণ্য যা খাওয়া যায় কিংবা
              গুদামজাত করা যায়, সেটাই রিবাউই পণ্য। 
            </li>
            <li>
              ইমাম শাফিঈ (রহ) বলেন, এই ৬ পণ্যের মূল বৈশিষ্ট্য হল খাদ্যবস্তু হওয়া
              ও মূদ্রা জাতীয় হওয়া। সোনা-রূপাকে মূদ্রা হিসেবে ব্যবহার করা যায় ও
              বাকি ৪ টি খাদ্য হিসেবে খাওয়া যায়। তাই যে কোন বস্তু যা মূদ্রা
              হিসেবে ব্যবহার করা যায় কিংবা খাবার হিসেবে খাওয়া যায়, সেটিই রিবাউই
              পণ্য হবে।  
            </li>
            <li>
              ইমাম আহমাদ ইবন হাম্বাল (রহ) এর কাছ থেকে তিনটি মত পাওয়া যায়। যার
              একটি ইমাম আবু হানিফার (রহ) অনুরূপ, একটি ইমাম শাফিঈ (রহ) এর অনুরূপ
              এবং তৃতীয়টি তার নিজস্ব মত যা হল, সোনা-রূপা ছাড়া অন্য যে কোন পণ্যের
              মধ্যে যদি খাদ্য জাতীয় হওয়া, পরিমাপ করে ও ওজন করে বিক্রি করা – এই
              তিনটি বৈশিষ্ট্য পাওয়া যায়, তাহলে তা রিবাউই পণ্য হিসেবে গণ্য হবে।
            </li>
          </ul>

          <p>
            কাজেই আমরা দুইরকম পণ্য পেলাম।
            <br /> প্রথমটি হল, যেসব পণ্য বিনিময় মাধ্যম হিসেবে ব্যবহৃত হয়। 
            <br /> দ্বিতীয়টি হল, খাদ্যজাতীয় পণ্য যা ওজন আর পরিমাপ করে বিক্রি করা
            যায়।  
          </p>

          <p>
            তাই যেসব পণ্য বিনিময় মাধ্যম হিসেবে ব্যবহৃত হয়, এবং যেসব খাদ্যজাতীয়
            পণ্য ওজন ও পরিমাপ করে বিক্রি করা যায়, সেইসব পণ্যকেই রিবাউই পণ্য
            বিবেচনা করে তার উপর রিবা সংক্রান্ত হুকুম বিবেচনা করতে হবে।  
          </p>

          <p>
            [এই পর্বে রিবা আল ফাদল নিয়ে প্রথম ধাপের আলোচনা করা হল। পরবর্তী পর্বে
            এর দ্বিতীয় ধাপ নিয়ে আলোচনা করা হবে ইনশাআল্লাহ]  
          </p>
          <p>ওয়াল্লাহু তা'আলা আ'লাম </p>
        </div>

        <hr />

        <div>
          <h4 className="fw-bold">পর্ব - ৫</h4>

          <p>
            গত পর্বে আমরা রিবা আল ফাদল নিয়ে প্রথম ধাপের আলোচনা করেছি। এই পর্বে
            দ্বিতীয় ধাপের আলোচনা করা হল।  
          </p>
          <p>
            আমরা এখন জানলাম রিবাউই পণ্য হাদীসে উল্লিখিত ৬ টি পণ্যেই সীমাবদ্ধ নয়,
            বরং যেসকল পণ্যের মাঝে রিবাউই পণ্যের মূল বৈশিষ্ট্য পাওয়া যাবে, সেসকল
            পণ্যই রিবাউই পণ্য হিসেবে গণ্য হবে। এখন আমরা রিবাউই পণ্যের পারস্পরিক
            বিনিময়ের কিছু হুকুম আরেকটু বিশ্লেষিত আকারে দেখব, যা পূর্বে অল্প
            আলোচনা করা হয়েছে।
          </p>

          <p>
            রিবাউই পণ্যের পারস্পরিক লেনদেনের মূলনীতিগুলো ইমাম নববী বেশ সুন্দর
            করে গুছিয়ে এনেছেন। তা নিম্নরূপ:
          </p>

          <ul>
            <li>
              যদি দুটো পণ্যের মূল বৈশিষ্ট্য ভিন্ন হয়, তাহলে পারস্পরিক লেনদেনের
              ক্ষেত্রে বেশ/কম ও বাকি রাখা উভয়ই জায়েয। যেমন, টাকার বিনিময়ে চাল
              ক্রয় করা। এক্ষেত্রে চালের পরিমাণ আর টাকার পরিমাণ এক হতে হবে কথা
              নেই এবং আপনি চাইলে বাকিতেও চাল কিনতে পারেন।
            </li>

            <li>
              যদি দুটো পণ্য একইরকম হয়, তাহলে পারস্পরিক লেনদেনের ক্ষেত্রে বেশ/কম
              ও বাকি রাখা উভয়ই নাজায়েয। যেমন, বাংলাদেশি টাকার বিনিময়ে বাংলাদেশি
              টাকা ক্রয় করা। পুরানো টাকা দিয়ে নতুন টাকা কেনা আমরা প্রায়ই দেখি।
              সাধারণত নতুন ১০০ টাকার নোট কিনতে পুরোনো ১১০ টাকা দিতে হয়। কিন্তু
              এটা রিবা। হালাল লেনদেন হতে হলে এক্ষেত্রে পুরোনো আর নতুন উভয় টাকার
              মূল্য সমান হতে হবে। অর্থাৎ ১০০ টাকার নতুন নোট কিনতে হলে বিনিময়ে
              পুরোনো নোট ১০০ টাকাই দিতে হবে। বেশি বা কম দেয়া যাবে না। এছাড়া এই
              লেনদেন বাকিতে করা যাবে না। আপনি বলতে পারেন না, “আমাকে নতুন ১০০
              টাকার নোট দাও, আমি তোমাকে এখন ৫০ টাকার পুরনো নোটটা দিলাম, বাকি ৫০
              টাকা আগামীকাল দেব।”
            </li>

            <li>
              যদি দুটো পণ্য ভিন্ন রকম হয় কিন্তু মূল বৈশিষ্ট্য একই হয়, তাহলে
              বেশ/কম জায়েয, কিন্তু বাকি রাখা নাজায়েয। যেমন, বাংলাদেশি টাকা দিয়ে
              ইউএস ডলার কেনা। আপনি বাংলাদেশি ৮০ টাকা দিয়ে ইউএসএ এর ১ ডলার কিনতে
              পারেন, অর্থাৎ বেশ/কম করতে পারেন, তবে তা একই মাজলিস বা সেশানে হতে
              হবে। বাকি রাখা যাবে না। আপনি আজকে বাংলাদেশি ৮০ টাকা দিলেন, ইউএস ১
              ডলার আগামীকাল নেবেন, এটা হতে পারবে না। 
            </li>
          </ul>

          <p>
            ইমাম নববীর মূলনীতির দ্বিতীয় যে নীতি, অর্থাৎ সমজাতীয় পণ্যের পারস্পরিক
            লেনদেনের বিষয়টি আরেকটু বিশ্লেষণ করা যায়। এই ধরণের বিনিময়ে মূলত চারটি
            বিষয় বিবেচনাধীন থাকে। আর সেসব হল:
          </p>

          <ul>
            <li>
              সমজাতীয় পণ্য পরস্পর বিনিময় করার ক্ষেত্রে উন্নত-অনুন্নত ভেদাভেদ করা
              যাবে না। অর্থাৎ, নতুন স্বর্ণের সাথে পুরাতন স্বর্ণের বিনিময়ের
              ক্ষেত্রে কম-বেশ করা যাবেনা। সমান সমান হতে হবে। তেমনি উন্নত মানের
              চালের বিনিময়ে নিম্ন মানের চাল নেয়ার ক্ষেত্রেও কম-বেশ করা যাবে না।
              সমান সমান হতেই হবে। এক্ষেত্রে ‘বাজার মূল্য’ মাথায় রাখা চলবে না।
              একটু আগে যে নতুন টাকা ও পুরোনো টাকার উদাহরণ দিয়েছি, সেটাও এই
              হুকুমের অধীন।
            </li>
          </ul>

          <p>
            তবে শরীয়ত এই ক্ষেত্রে একটি সমাধান দেয়। সেটি হল, যদি পণ্যগুলো
            বিক্রয়যোগ্য হয়, তবে একটি পণ্য বিক্রি করে দিয়ে তা টাকায় রূপান্তর করে,
            তারপর অন্য পণ্যটি কেনা যায়। ফলে আর রিবায় লিপ্ত হবার সম্ভাবনা থাকে
            না। যেমন, যদি ভালো মানের এক কেজি গম, আর একটু নিম্ন মানের দুই কেজি
            গমের মাঝে  বিনিময় করা হয়, সেটা হবে রিবা। এক্ষেত্রে সমাধান হল, নিম্ন
            মানের ২ কেজি গমকে বাজারে বিক্রি করে দিয়ে যে টাকা পাওয়া যাবে, সেই
            টাকা দিয়ে ভালো মানের  এক কেজি গম কেনা। 
          </p>

          <ul>
            <li>
              সমজাতীয় পণ্য যদি একইরূপ হয়, তাহলে লেনদেনে কোনরূপ বেশ/কম করা যাবে
              না। ধরা যাক, আপনার কাছে কিছু স্বর্ণ আছে আর আপনি সেই স্বর্ণের
              বিনিময়ে একটি শো-পীস এর দোকান থেকে সম্পূর্ণ স্বর্ণের তৈরি একটি
              শো-পীস কিনতে চান। এই ক্ষেত্রে আপনাকে সেই স্বর্ণের শো-পীস কিনতে
              সমপরিমাণ স্বর্ণ দিয়েই কিনতে হবে। যদি শো-পীসটি ১ ভরি ওজনের স্বর্ণ
              দিয়ে তৈরি হয়, তাহলে তা আপনাকে ১ ভরি স্বর্ণের বিনিময়েই কিনতে হবে।
              অথবা আপনার স্বর্ণকে আগে বিক্রি করে টাকায় রূপান্তর করে তারপর
              শো-পীসটি কিনতে হবে।  
            </li>

            <li>
              সমজাতীয় পণ্য যদি বৈশিষ্ট্যগত ভাবে কোনরকমের ভিন্ন বস্তুতে পরিণত হয়,
              অর্থাৎ দুটি সমজাতীয় পণ্যের মাঝে স্পষ্ট ভিন্নতা দেখা যায়, তাহলে
              বেশ/কম করা জায়েয। অর্থাৎ, যদি একটি পণ্য কাঁচামাল হয়, আর অপর পণ্যটি
              সেই কাঁচামাল দ্বারা প্রস্তুত কোন বস্তু হয়, এক্ষেত্রে বেশ/কম করা
              যাবে। যেমন, ইঞ্জিন তৈরি হয় লোহা লক্কড় দিয়ে। এখন লোহার বিনিময়ে কেউ
              যদি কোন একটি ইঞ্জিন নিতে চায়, তাহলে সেক্ষেত্রে বেশ/কম করা যাবে,
              যেহেতু শিল্পায়নের ফলে লোহার মৌলিক অবস্থার পরিবর্তন হয়ে গেছে। ঠিক
              একই ভাবে তুলার সাথে সুতা কিংবা সুতার সাথে কাপড়ের লেনদেনের
              ক্ষেত্রেও এই হুকুম প্রযোজ্য। 
            </li>
            <li>
              সমজাতীয় পণ্য যদি সোনা, রূপা বা মূদ্রা জাতীয় বস্তু হয়, অর্থাৎ
              বিনিময় মাধ্যম হয়, তাহলে পারস্পরিক লেনদেনের সময় নগদ তো হতে হবেই,
              সাথে উভয় পক্ষ নিজ নিজ পণ্যের উপর দখল প্রতিষ্ঠা করতে হবে। একে বলা
              হয় কাব্দ (বাংলায় কবজা বলা চলে)। অর্থাৎ স্পষ্টভাবেই তা দখলে চলে
              আসতে হবে। উদাহরণস্বরূপ, যায়েদ তার ৫০ ভরি রূপা যদি উমারের ৭ ভরি
              স্বর্ণের সাথে বিনিময় করতে চায়, তাহলে উভয়কেই নগদ বিনিময় করে
              স্পষ্টভাবেই সোনা ও রূপার দখল নিতে হবে। অর্থাৎ, যায়েদ ৭ ভরি স্বর্ণ
              তার কব্জায় নিয়ে নেবে আর উমার ৫০ ভরি রূপা তার কব্জায় নিয়ে নেবে। মূল
              বিষয় হল যায়েদ বা উমার উভয়েই নগদ বিনিময় করলেও, তা যদি নিজেদের
              কব্জায় নিয়ে না আসে, তাহলে লেনদেন শুদ্ধ হবে না। কিন্তু অন্যান্য
              পণ্যের ক্ষেত্রে এই কড়াকড়ি প্রযোজ্য নয়। যেমন, ফয়সাল যদি এক কেজি লবন
              যুবাইর এর আধা কেজি চিনির সাথে বিনিময় করে, তাহলে উভয়েই যদি নিজেদের
              পণ্যের নির্দিষ্ট পরিমাণের দিকে নির্দেশ করে বলে, “আমরা বিনিময়
              করলাম” আর যদি ফয়সাল চিনির আর যুবাইর লবণের স্পষ্ট দখল না নিয়ে বলে
              “কিছুক্ষণ পর নিচ্ছি”, তাহলে এই বিনিময় শুদ্ধ। কিন্তু মূদ্রা জাতীয়
              পণ্য বিনিময়ের ক্ষেত্রে এটা প্রযোজ্য নয়। এক্ষেত্রে স্পষ্টভাবেই দখলে
              নিয়ে নেয়া জরুরী।  
            </li>
          </ul>

          <p>ওয়াল্লাহু তা'আলা আ'লাম </p>
        </div>
      </div>
    ),
  },

  {
    _ID: "smeblog002.1",
    name: "Managing an income source for a Muslim",
    img: manageincome,
    slug: "managing-income-source",
    author: "Rahat Imroz Ahmed",
    details: (
      <div>
        <div>
          <h4 className="fw-bold">
            The best act of Sadaqah Jariyah that will give you an immeasurable
            reward
          </h4>
          <p>
            We only got this Dunya (worldly life) to please Allah and earn his
            grace. Once we die, the chance of worshiping him goes away. Is there
            a way to get the continuous rewards of Allah even after a Muslim
            leaves this world?
          </p>
          <p>
            Alhamdulillah, Allah has also given us the chance to earn his
            rewards after death through Sadaqah Jariyah.
          </p>
          <p>
            The Prophet Muhammad(pbuh) said: “When a human being dies, all one’s
            deeds cease, save three: a Sadaqah Jariyah, [religious] knowledge
            [one leaves behind] from which others benefit, and a righteous child
            who prays for one” (Muslim).
          </p>
          <p>
            Managing an income source for a Muslim as a Sadaqah Jariyah is one
            of the best ways to get Allah’s blessing in the world and the
            hereafter.
          </p>
          <p>
            Before explaining why this act of charity is the best way to please
            Allah, let’s understand the difference between Sadaqah and Sadaqah
            Jariah.
          </p>
          <h4 className="fw-bold">Sadaqah and Sadaqah Jariyah</h4>
          <p>
            General Sadaqah is a one-time donation. For example, giving someone
            food to alleviate his hunger could be a way of normal sadaqah. Here
            the recipient gets the help for once.
          </p>
          <p>
            Sadaqah Jariyah is a special type of charity that is long-term and
            ongoing. The recipient of sadaqah Jariyah will get continuous help
            from this special kind of charity. In hadith, we find an example of
            Sadaqah Jariyah.
          </p>
          <p>
            Rasulullah (SAW) said: "If a Muslim plants a tree or sows seeds, and
            then a bird or a person or an animal eats from it, it is regarded as
            a charitable gift for him"(Sahih Bukhari).
          </p>
          <p>
            As long as animals and humans benefit from the tree, the giver will
            receive a continuous reward. So acts like planting a tree, setting
            up a water pump, or managing an income source for a brother will
            benefit the people for a long time. Even if the giver passes away,
            he will still get rewards from Allah for this sadaqah.
          </p>
          <h4 className="fw-bold">
            Importance of managing an income for a Muslim
          </h4>
          <p>
            We should always prefer giving our sadaqah in the most necessary
            cases. There is a section of people who are skilled yet unemployed
            due to fewer work opportunities. On the other hand, there are a huge
            number of people who don't have the training and basic amenities of
            life. The recent covid crisis has made the situation worse for both
            types of people in society.
          </p>
          <p>
            With little or no income they struggle to meet both ends. A huge
            number of lower-middle-class people lost their jobs and incurred
            losses in their businesses during the lockdown.
          </p>
          <p>
            Those who got covid and survived are going through a financial
            crisis as they had to spend vast money to get cured of the virus.
            Many sisters lost their husbands during covid, the only breadwinner
            of the family. As a result, they are now struggling to run the
            family.
          </p>
          <p>
            If such a dire situation is not addressed immediately, it can badly
            affect the families and societies at large. Many families are
            already living in huge debt. Some families are starving for days and
            weeks of not being able to make an income.
          </p>
          <p>
            The worst consequence is a lot of unemployed young people are
            getting involved in unlawful activities and haram income so that
            they can somehow manage money in a short time.
          </p>
          <p>
            Managing an income for them as a sadaqah Jariyah will help them
            immensely and alleviate the dire situation. Here’s how donations can
            help them:
          </p>
          <ul>
            <li>
              They will be able to pay off huge amounts of debts and interest
              associated with it.
            </li>
            <li>
              The donations can be used to give proper education and support to
              those young enthusiastic people who are willing to work outside.
            </li>
            <li>
              It will also let the widow sisters receive training so that they
              could learn handicraft works.
            </li>
            <li>
              Brothers can set up small businesses by managing the capital.
            </li>
            <li>
              Donations could be invested in training institutions to create
              more halal employment for the lower-income people.
            </li>
            <li>
              There are a significant number of people with physical and mental
              disabilities. Proper treatment,support and work opportunities can
              be given with the donation for this class of people.
            </li>
            <li>
              It will prevent the youth from getting engaged in unlawful
              activities and encourage them to earn a halal income.
            </li>
          </ul>
          <p>
            Managing income for these families can provide them with a secure
            and sound life that will benefit generations to come.
          </p>
          <h4 className="fw-bold">
            Rewards for helping your Muslim brothers and sister
          </h4>
          <p>
            By giving this form of charity the donor can earn immense rewards
            and blessings in this world and hereafter. It will increase and
            purify the wealth.
          </p>
          <p>
            Muhammad, upon him be peace, said: “Charity does not in any way
            decrease the wealth and the servant who forgives, Allah adds to his
            respect; and the one who shows humility, Allah elevates him in the
            estimation (of the people).” (Muslim)
          </p>
          <p>
            Those who will give Sadaqah Jariyah, Allah will ease the hardship
            and remove calamities from his life. Most importantly, it will be a
            shade for him on the day of judgment and protect him from hellfire.
          </p>
          <p>
            The Prophet(pbuh) said: “The believer’s shade on the Day of
            Resurrection will be his charity.” (Al-Tirmidhi)
          </p>

          <h4 className="fw-bold">Conclusion</h4>
          <p>
            We cannot completely worship and please Allah without treating his
            creations with the utmost kindness and caring. Even if we look at
            the life of our prophets and the greatest Prophet Muhammad(PBUH), we
            find they never hesitated to serve their Muslim brothers and
            sisters.
          </p>
          <p>
            It is the duty of all Muslims to help those organizations that are
            working in the field to address such problems in society.
          </p>
          <p>
            That’s why Sadaqah-Made-Easy(SME) has launched a platform where you
            can easily find and donate to various sadaqah Jariyah projects.
          </p>
        </div>
      </div>
    ),
  },

  {
    _ID: "smeblog003.1",
    name: "Sylhet and Sunamganj Coverage Map",
    img: sylhetcoverage,
    slug: "coverage-map",
    author: "Team SME",
    details: (
      <div>
        <div>
          <p className="mb-3">
            কভারেজ ম্যাপ - <b>২৫শে জুন, ২০২২</b>
            <br />
            <br />
            👇 👇 নিচে আছে ফ্রী প্রো টিপ
            <br />
            <br />
            আমাদের প্লাটফর্মের এনলিস্টেড রেফারার এবং অর্গানাইজেশনগুলো সহ
            অন্যান্য অর্গানাইজেশন গুলো সিলেট এবং সুনামগঞ্জের কোন কোন থানায় কাজ
            করছে তার একটি কভারেজ ম্যাপ আমরা তৈরি করতে চেষ্টা করেছি। এক মাস আগে
            থেকে যারা কাজ করছে তারাই প্রাধান্য পেয়েছে। তার মানে এই না যে নতুন
            অর্গানাইজেশনগুলোর দিকে আমরা নজর দেই নি। ম্যাপ ভাল করে দেখলে দেখা
            যাবে কিছু কিছু নতুন অর্গানাইজেশনও যুক্ত করা হয়েছে যারা মূলত
            পুরোনোগুলোর সাথে কোলাবোরেট করে কাজ করছে। ম্যাপে অন্তর্ভুক্ত
            সংস্থাগুলোর কোনো কভার পয়েন্ট বাদ পড়লে কমেন্টে / ইনবক্সে জানাতে
            পারেন।
            <br />
            <br />
            এই কভারেজ ম্যাপটি তৈরি করার উদ্দেশ্য হল, যে থানাগুলোতে ত্রাণ একদমই
            পৌঁছায়নি সেগুলো ফাইন্ড আউট করা। ১০০% নিখুঁত আউটপুট নাও আসতে পারে তবে
            এটা নিশ্চিত যে যারা মাঠে কাজ করছে তাদের জন্য বাদ পড়ে যাওয়া থানাগুলো
            খুঁজে পেতে সহজ হবে।
            <br />
            <br />
            👉 যেসব সংস্থাগুলো তাদের পেইজ থেকে নিজেদের কাজের প্রচারণা চালাচ্ছেন
            তাদের প্রতি আমাদের একটি ছোট্ট প্রো টিপঃ একদমই যেসব জায়গায় ত্রাণ
            পৌঁছায়নি, সেসব জায়গায় ত্রাণ দিতে গেলে বলবেন না যে, "এই এলাকায় এর আগে
            কোনো সংস্থা / কেউ ত্রাণ দিতে আসে নি", বরং বলুন, "বিভিন্ন চ্যালেঞ্জের
            কারণে কোনো সংস্থা হয়তো এখনো এই এলাকায় আসতে পারে নি..."
          </p>
          <h4 className="fw-bold mt-4">
            সিলেট জেলার কভারেজ ম্যাপ, ২৫শে জুন, ২০২২
          </h4>
          <img
            src={sylhetcoverage}
            alt="sylhetghonaighat"
            className="w-100 mt-3 mb-3"
          />
          <hr />
          <h4 className="fw-bold">
            {" "}
            সুনামগঞ্জ জেলার কভারেজ ম্যাপ, ২৫শে জুন, ২০২২
          </h4>
          <img
            src={sunamgonjcoverage}
            alt="sylhetghonaighat"
            className="w-100 mt-3 mb-5"
          />

          <a
            href="https://www.facebook.com/sadaqahmadeeasy/posts/pfbid028Zfa3bL9MAtdtmptusd7H5F6rojyXyYA93hLUEqMKiswSTwrycUgKZZGkzoMU9R1l"
            target="__blank"
            className="text-white bg-primary rounded p-2"
          >
            ফেসবুকে শেয়ার করুন
          </a>
        </div>
      </div>
    ),
  },
];

export function getBlogs() {
  return blogs.filter((o) => o);
}
