import React from "react";

function FixedFooter() {
  return (
    <footer className="fixed-footer">
      Copyright © 2024 SadaqahMadeEasy - All rights reserved - Donation doesn't
      decrease wealth
    </footer>
  );
}

export default FixedFooter;
