//import sanabilqurban from "../img/sanabilqurban.jpeg";
import ovijatrikqurban from "../img/ovijatrikqurban.jpeg";

//last serial: 2

const QurbanProjectList = [
  //  {
  //    _ID: "sdqmeq001.1",
  //    projectImg: sanabilqurban,
  //    projectHeading: "Sab'a Sanabil Qurban Project 2024",
  //    slug: "qurban-saba-sanabil",
  //    projectDetails: (
  //      <div>
  //        <p>
  //          {" "}
  //          প্রতিবছর যখন আমার আপনার বাসায় কুরবানির পশু উপস্থিত হয়, বাসার শিশুরা
  //          আদর করে নতুন অতিথিটিকে।। ঠিক সে সময় দেশের বিভিন্ন প্রান্তের মানুষ যারা
  //          কুরবানি করার সামর্থ্য রাখেনা, অনেক ছোট ছোট ছেলে মেয়ে যাদের বাবা মা
  //          মারা গেছেন, অনেক পরিবার যারা লোক লজ্জায় মানুষের কাছে যায় না তাদের
  //          মধ্যে অনেকে অপেক্ষা করে থাকে আমাদের মত একটা ছোট প্রতিষ্ঠানের ডোনারদের
  //          পক্ষ থেকে কুরবানীর পশুর আশায়। কিছু জায়গা থেকে অনেক সময় একই মানুষ
  //          একাধিকবার যোগাযোগ করে থাকে। এ মানুষগুলোকে সরাসরি না করতে কেমন যেন
  //          ইচ্ছা হয় না।{" "}
  //        </p>

  //        <p>
  //          বিগত কয়েক বছর যারা আমাদের মাধ্যমে কোরবানি আদায় করেছেন তা জানেন
  //          কোরবানির প্রজেক্ট আমরা শুধু কুরবানী আদায় করি না সাথে এটাও চেষ্টা করি
  //          যেন অত্যন্ত এলাকায় এটা আমাদের পক্ষ থেকে একটা দাওয়াহর কাজ হতে পারে
  //          বিশেষ করে পাহাড়ী এলাকা এবং উত্তরবঙ্গের বিভিন্ন জায়গায় আমরা আপনাদের
  //          দেয়া পশুগুলো কোরবানি করে থাকি আলহামদুলিল্লাহ। আমাদের সব সময় চেষ্টা
  //          থাকে যেন একদম প্রত্যন্ত এলাকা থেকে গরুগুলো কিনে স্বল্প খরচের মধ্যে
  //          আদায় করতে পারি যাতে করে যাদের বাজেট কম তারাও কোরবানি গুলো এরকম
  //          প্রত্যন্ত এলাকায় আদায় করে ফেলতে পারে। এছাড়া চাইলে অনেকে আছেন একাধিক
  //          কুরবানী করে থাকেন তাদের প্রতি অনুরোধ একাধিক কোরবানির ক্ষেত্রে অন্তত
  //          নওমুসলিম এলাকা কিংবা মিশনারি আক্রান্ত এলাকাগুলো তে এক অন্তত একটি নফল
  //          কোরবানীর জন্য বাজেট রাখতে পারেন ইনশাআল্লাহ।
  //        </p>

  //        <p>
  //          কুরবানি প্রজেক্টে ওয়াজিব ও নফল কুরবানী দিয়ে অংশ নিতে চাইলে নিচের
  //          একাউন্ট গুলোতে আগামী ৮ই জিলহজ্জ এর মধ্যে আপনার টাকা পাঠিয়ে দিন।
  //        </p>

  //        <p>
  //          {" "}
  //          নোট এ বছরে আমাদের কুরবানী প্রজেক্টর প্যাকেজ: (মোবাইল ব্যাংকিং খরচ সহ){" "}
  //        </p>

  //        <ol>
  //          <li> একটা ছোট সাইজের গরু - ৭০০০০/= </li>
  //          <li> ছোট সাইজের গরুর প্রতি ভাগ- ১০২০০/- </li>
  //          <li> একটা মাঝারি সাইজের গরু - ৯১০০০/- </li>
  //          <li> একটা মাঝারি সাইজের গরুর প্রতি ভাগ- ১৩৬০০/- </li>
  //          <li> একটা মাঝারি সাইজের খাসি - ১৬০০০/- </li>
  //          <li> একটা মাঝারি সাইজের ছাগল - ১২০০০/- </li>
  //          <li> একটা মাঝারি সাইজের ভেড়া - ১১০০০/- </li>
  //        </ol>

  //        <p>
  //          {" "}
  //          এছাড়া উপরের একাউন্টগুলোতে যে কোন পরিমাণ নফল সাদাকা করতে পারবেন আমাদের
  //          কুরবানির ফান্ডে ইনশাআল্লাহ। নফল সাদাকাহ থেকেও দেশের বিভিন্ন প্রান্তে
  //          আপনার নফল কুরবানী আদায় করা হবে ইন শা আল্লাহ।{" "}
  //        </p>

  //        <ul>
  //          {" "}
  //          নিয়মাবলী:
  //          <li>
  //            {" "}
  //            টাকা পাঠানোর পরে আমাদের পেইজের ইনবক্সে কোন নাম্বার থেকে কত টাকা
  //            পাঠিয়েছেন এবং কোন পশুর জন্য (ভাগে দিলে ভাগের সংখ্যা) সেটা উল্লেখ
  //            করে দেবেন। সেই সাথে আপনার মোবাইল নম্বরটিও ইনবক্সে দিয়ে দিবেন।
  //            প্রজেক্ট কোড DF74 উল্লেখ করে দিবেন মেসেজে।{" "}
  //          </li>
  //          <li>
  //            {" "}
  //            টাকা পাঠানোর পরে আপনার পশুর টোকেন নাম্বারের জন্য মেসেজ দিয়ে রাখুন।
  //            আগামী মাসের ১৩-১৪ই জুনের মধ্যে আপনাকে টোকেন দিয়ে দেয়া হবে
  //            ইনশাআল্লাহ। নফল সাদাকাহ পাঠালে টোকেন নম্বর লাগবে না।{" "}
  //          </li>
  //          <li>
  //            {" "}
  //            ওয়াজিব কুরবানীর ক্ষেত্রে বিকাশ, রকেট বা নগদে টাকা পাঠালে চার্জ সহ
  //            টাকা পাঠাবেন ইন শা আল্লাহ।{" "}
  //          </li>
  //          <li>
  //            {" "}
  //            সর্বোপরি আমাদের এই কুরবানীর প্রজেক্টে সবাইকে নিজ নিজ হালাল উপার্জন
  //            থেকে অংশগ্রহণের অনুরোধ করছি।{" "}
  //          </li>
  //        </ul>
  //      </div>
  //    ),
  //    projectContact: (
  //      <div>
  //        <hr />
  //        <h4 className="mb-4">টাকা পাঠানোর একাউন্ট নাম্বারঃ</h4>

  //        <p>Nagad Personal: 01309-336883</p>
  //        <p> Rocket personal: 01309-336883-8 </p>
  //        <p>bKash Personal: 01309-336883 </p>

  //        <h4 className="mb-4">ব্যাংক একাউন্ট নাম্বার ১ঃ </h4>
  //        <p>Bank: Islami Bank Bangladesh Ltd. </p>
  //        <p>A/C No(Current): 20507310100010403 </p>
  //        <p>A/C Name: Saba Sanabil Foundation</p>
  //        <p> Branch: Kalampur SME (Dhaka North) </p>

  //        <h4 className="mb-4">ব্যাংক একাউন্ট নাম্বার ২ঃ </h4>
  //        <p>Bank: Dutch Bangla Bank Ltd.</p>
  //        <p>A/C No(Current): 294.110.000.5513 </p>
  //        <p>A/C Name: Sab-a-Sanabil</p>
  //        <p> Branch: Lalmonirhat </p>
  //        <p>Routing: 090520466</p>
  //        <hr />
  //        <p>PayPal: sabasanabil.org@gmail.com</p>
  //        <hr />
  //      </div>
  //    ),
  //    projectFbProfile: `https://www.facebook.com/mohammadnahidhasanrony`,
  //    projectOrgName: "Sab'a Sanabil Foundation",
  //    projectOrg: { _ID: "sdqmeq001", name: "Sab'a Sanabil Foundation" },
  //    projectCreated: "May 2024",
  //    projectTag: "কুরবানী, Eid Al-Adha",
  //    projectLink:
  //      "https://www.facebook.com/SabaSanabilFoundation/posts/pfbid0kMbdTQXsVMfPENRCiLYs7zmVuYr6K7ADCbJqRWQv1Jd2NVM57QxHb2EGLT7QJweBl",
  //  },

  {
    _ID: "sdqmeq002.1",
    projectImg: ovijatrikqurban,
    projectHeading: "কুরবানী প্রজেক্ট by অভিযাত্রিক",
    slug: "qurban-ovijatrik",
    projectDetails: (
      <div>
        <h3>
          {" "}
          আলহামদুলিল্লাহ, শুরু হয়েছে অভিযাত্রিকের কোরবানি প্রজেক্ট -২০২৪।{" "}
        </h3>{" "}
        <p>
          {" "}
          গত বছর আপনাদের সকলের অংশগ্রহণ এর মাধ্যমে আমরা সর্বমোট ১৫টি গরু ও ৩৬টি
          ছাগল দেশের বিভিন্ন স্থানে কোরবানি করতে সক্ষম হয়েছিলাম। এবছর আমাদের
          লক্ষ্যমাত্রা ২০টি গরু এবং ৫০টি ছাগল কোরবানি করা ইনশাআল্লাহ। কোরবানি
          সংক্রান্ত বিস্তারিত ফ্লায়ার এবং গত বছরের ১৫টি গরুর ছবি পোস্টের সাথে
          সংযুক্ত করা হয়েছে।{" "}
        </p>{" "}
        <p>
          আপনার কোরবানির পশু দেশের কোন অঞ্চলে কোরবানি করা হচ্ছে, পশু কোরবানির
          উপযুক্ত কিনা এজন্য দাত দেখে কোরবানি ক্রয় করা থেকে শুরু করে জবাই করা
          এবং গরীব দু:খীদের মাঝে গোশত বিতরণের A টু Z আপনাদের সামনে উপস্থাপন করা
          হবে ইনশাআল্লাহ।{" "}
        </p>{" "}
        <p>
          {" "}
          গত বছরের ন্যায় প্রতিটি পশুর জন্য থাকবে ক্রমিক নম্বর সহ আলাদা আলাদা
          ব্যানার যার মাধ্যমে আপনি সহজেই আপনার পশুকে আলাদা করতে পারবেন। তাই
          নিশ্চিন্ত মনে আপনার পশু কোরবানির দায়িত্ব অভিযাত্রিক Ovijatrik কে দিতে
          পারেন।{" "}
        </p>{" "}
        <p>
          এবছর ব্যবস্থাপনা খরচ সহ প্রতিটি গরুর ভাগা নির্ধারণ করা হয়েছে ১২,৫০০
          টাকা এবং প্রতিটি ছাগলের মূল্য নির্ধারণ করা হয়েছে ১২,৫০০ টাকা।{" "}
        </p>{" "}
        <b>
          {" "}
          বিস্তারিত দেখতে আমাদের ফেসবুক পেইজে চোখ রাখুন এবং কোরবানির পশুর ভাগা
          বুকিং দিতে হোয়াটসঅ্যাপে যোগাযোগ করুন।{" "}
        </b>{" "}
        <p>
          {" "}
          আসুন সবাই মিলে কোরবানির ঈদের আনন্দ ভাগাভাগি করি গরীব দু:খী মানুষদের
          সাথে।{" "}
        </p>
      </div>
    ),
    projectContact: (
      <div>
        <hr />
        <h4 className="mb-4">টাকা পাঠানোর একাউন্ট নাম্বারঃ</h4>
        <p>নগদ/বিকাশ: </p>
        <p>01717017645</p>
        <p>01734194560</p>
        <p>Reference: OKP (sadaqahmadeeasy)</p>
        <br />
        <h4 className="mb-4">ব্যাংক একাউন্ট নাম্বারঃ </h4>
        <p>Bank: South Bangla Agriculture & Commerce Bank Limited (SBAC)</p>
        <p>A/C Name: Ovijatrik Somaj Kollyan Sangsthan</p>
        <p>A/C No: 0054131000018 </p>
        <p>Routing Number: 270280676 </p>
        <p> Branch: Dinajpur </p>

        <hr />
      </div>
    ),
    projectFbProfile: `https://sadaqahmadeeasy.com/org/ovijatrik`,
    projectOrgName: "অভিযাত্রিক",
    projectOrg: { _ID: "sdqmeq002", name: "অভিযাত্রিক" },
    projectCreated: "May 2024",
    projectTag: "কুরবানী, Eid",
    projectLink:
      "https://www.facebook.com/md.arifulhuq/posts/pfbid02odQQjf17QPVAgWjpuUYneZTrbt8CG2RWC58dQNg4mzoMvAymkie6JXWfGXAPK7aDl",
  },
];

export function getQurbanProjectList() {
  return QurbanProjectList;
}
